import React, { Component, forwardRef } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import MaterialTable from "material-table";
// Material Table Icons Imports
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { CircularProgress } from "@material-ui/core";
import { Flatware } from "@mui/icons-material";

// Material Table Icons Defined
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class CaetV1 extends Component {
  constructor() {
    super();
    this.state = {
      marketwatch: [],
    };
  }

  componentDidMount() {
    fetch("https://fortune.aiodock.com/fortune/marketwatch", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        this.setState({ marketwatch: response });
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const columns = [
      { title: "Phase", field: "phase", defaultSort: "asc" },
      { title: "Sector", field: "sector" },
      { title: "Size", field: "size" },
      { title: "Price", field: "rate" },
    ];

    const options = {
      sorting: true, 
	  search:false
    };

    return (
      <div className="liton__shoping-cart-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <MaterialTable
                icons={tableIcons}
                title="Market Watch"
                columns={columns}
                data={this.state.marketwatch}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CaetV1;
