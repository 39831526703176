import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Table, Card, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { FaUserAlt } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SidebarV1 from "../blog-components/sidebar-v2";
import BlogRecentsThree from "../blog-components/blog-slider-v2";
import CallToActionV6 from "../section-components/call-to-action-v6";
// import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook, FaWhatsapp, FaInstagram, FaTwitter } from "react-icons/fa";
import { Helmet } from "react-helmet";

function formatphonenumber(phoneNumber) {
  return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
    3,
    5
  )}-${phoneNumber.substring(5)}`;
}

function ShopDetailsCommercial() {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [display, setDisplay] = useState(true);
  const [width, setWidth] = useState(400);
  const [property, setProperty] = useState([]);
  const [property2, setProperty2] = useState(true);
  const [plotImages, setPlotImages] = useState([]);
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    style: { margin: "0 10px" },
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          position: "absolute",
          top: "50%",
          left: "-20px",
          transform: "translateY(-50%)",
          zIndex: "1",
          fontSize: "24px",
          color: "#000080",
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          position: "absolute",
          top: "50%",
          right: "-20px",
          transform: "translateY(-50%)",
          zIndex: "1",
          fontSize: "24px",
          color: "#000080",
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }
  const [images, setImages] = useState([]);
  let { id } = useParams();

  // let phoneNumber = property.details.callnow;
  let phoneNumber = property.details?.callnow;

  if (phoneNumber && phoneNumber.charAt(0) === "0") {
    phoneNumber = "+92" + phoneNumber.slice(1);
  }

  // useEffect(() => {
  //   fetch("https://fortune.aiodock.com/fortune/plot/single/" + id, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       if (res.status === 400) {
  //         alert("No Plot Found");
  //       } else {
  //         return res.json();
  //       }
  //     })
  //     .then((response) => {
  //       setProperty(response[0]);
  //       fetch(
  //         "https://fortune.aiodock.com/fortune/plot/image/" + response[0].image,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       )
  //         .then((res) => {
  //           if (res.status === 400) {
  //             alert("No Plot Found");
  //           } else {
  //             return res.json();
  //           }
  //         })
  //         .then((image) => {
  //           setFile(response[0].datatype + "base64," + image.data);
  //           setExtension(response[0].image.split(".")[1]);
  //         });
  //     });
  // }, []);

  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/plot/single/" + id, {
    fetch("https://property.aiodock.com/fortune/plot/single/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setProperty(response[0]);
        // Extract image URLs from the details object
        const imageUrls = [];
        for (const property in response[0].details) {
          if (
            property.startsWith("image") &&
            response[0].details[property].startsWith("http")
          ) {
            // If the property name starts with "image" and its value is a URL, add it to the array
            imageUrls.push(response[0].details[property]);
          }
        }

        // Set the imageUrls array to the images state variable
        setImages(imageUrls);
        // -------------------------------------------------------------

        if (response[0].details.image) {
          fetch(
            // "https://fortune.aiodock.com/fortune/plot/agentImage/" +
            "https://property.aiodock.com/fortune/plot/agentImage/" +
              response[0].details.image,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => {
              if (res.status === 400) {
                alert("No Agent Found");
              } else {
                return res.json();
              }
            })
            .then((image) => {
              setFile(response[0].details.datatype + "base64," + image.data);
              // setExtension(response[0].imageAgent.split(".")[1]);
              if (response[0].imageAgent) {
                setExtension(response[0].details.extension.split(".")[1]);
              }
            });
        }
        // Fetching Images
        fetch("https://property.aiodock.com/fortune/gallery/" + id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status === 400) {
              alert("No Image Found");
            } else {
              return res.json();
            }
          })
          .then((response) => {
            setPlotImages(response);
          });
      });
  }, []);

  const openGraphTags = {
    title: property.title,
    // description: property.description,
    description: property.details && property.details.description,
    image: file,
    url: window.location.href,
  };

  return (
    <div>
      <Helmet>
        {/* Ensure that content values are strings */}
        <meta property="og:title" content={String(openGraphTags.title)} />
        <meta
          property="og:description"
          content={String(openGraphTags.description)}
        />
        <meta property="og:image" content={String(openGraphTags.image)} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={String(openGraphTags.url)} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={String(openGraphTags.title)} />
        <meta
          name="twitter:description"
          content={String(openGraphTags.description)}
        />
        <meta name="twitter:image" content={String(openGraphTags.image)} />
      </Helmet>

      <div className="container-fluid mt-5">
        {property ? (
          <div className="container-fluid">
            <Row className="mx-5">
              {/* <Col xl={1} md={1} lg={1} xs={12}></Col> */}
              <Col xl={8} md={8} lg={8} xs={12}>
                <div
                  style={{
                    backgroundColor: "#e8e9ee",
                  }}
                >
                  {/* price and city on top of image: */}

                  <div
                    className="ltn__team-details-member-info"
                    style={{ marginLeft: "2em" }}
                  >
                    {/* <h2
                    style={{
                      fontWeight: "800",
                      color: "#1A2B56",
                      paddingTop: "1em",
                    }}
                  >
                    {property.title}
                  </h2> */}
                    <h2
                      style={{
                        // marginTop: "-1em",
                        paddingTop: "1em",
                        fontWeight: "600",
                        fontSize: "1.5em",
                        color: "rgb(255, 90, 60)",
                      }}
                    >
                      {/* PKR {property.price} */}
                      PKR {property.price ? property.price : property.title}
                    </h2>
                    <h6 style={{ color: "black" }} className="text-uppercase ">
                      <i style={{ color: "black" }} className="flaticon-pin" />{" "}
                      {property.city}
                    </h6>
                  </div>

                  <Col lg={12} sm={12}>
                    <div
                      style={{
                        display: display ? "block" : "none",
                        alignItems: "center",
                      }}
                    >
                      {/* <Carousel>
                      {plotImages.map((image, index) => (
                        <div key={index}>
                          <img
                            style={{ padding: "10px" }}
                            src={plotImages}
                            alt={`Slide ${index + 1}`}
                          />
                        </div>
                      ))}
                    </Carousel> */}
                      {plotImages && plotImages.length > 0 ? (
                        <Carousel>
                          {plotImages.map((image, index) => (
                            <div key={index}>
                              <img
                                style={{ padding: "10px" }}
                                src={`https://property.aiodock.com/fortune/gallery/view/${image.image}`}
                                alt={`Slide ${index + 1}`}
                              />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <Carousel>
                          {images.map((image, index) => (
                            <div key={index}>
                              <img
                                style={{ padding: "10px" }}
                                src={image}
                                alt={`Slide ${index + 1}`}
                              />
                            </div>
                          ))}
                        </Carousel>
                      )}
                    </div>
                  </Col>

                  <h3
                    style={{
                      fontWeight: "600",
                      color: "#1A2B56",
                      marginLeft: "1em",
                      paddingBottom: "1em",
                    }}
                  >
                    Property Details
                  </h3>
                </div>
                <Row>
                  <div className="ltn__team-details-member-info  mb-40">
                    <Table striped>
                      <tbody>
                        {/* {property.details &&
                      property.details.showPlotNo == true ? (
                        <tr>
                          <th>Plot No</th>
                          <td>{property.details && property.details.plotNo}</td>
                          <th>Street No</th>
                          <td>
                            {property.details && property.details.streetTitle}
                          </td>
                        </tr>
                      ) : null} */}
                        {property.details &&
                        property.details.showPlotNo == true ? (
                          <tr>
                            <th>Plot No</th>
                            <td>
                              {property.details && property.details.plotNo}
                            </td>
                            <th>Street No</th>
                            <td>
                              {/* {property.details && property.details.streetTitle} */}
                              {property.details && property.details.streetTitle
                                ? property.details.streetTitle
                                : "-"}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <th>Plot No</th>
                            <td>-</td>
                            <th>Street No</th>
                            <td>
                              {/* {property.details && property.details.streetTitle} */}
                              {property.details && property.details.streetTitle
                                ? property.details.streetTitle
                                : "-"}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th>Plot Size</th>
                          {/* <td>{property.size}</td> */}
                          <td>{property.size ? property.size : "-"}</td>
                          <th>Category</th>
                          {/* <td>{property.details && property.details.category}</td> */}
                          <td>
                            {/* {property.details && property.details.category
                            ? property.details.category
                            : "-"} */}
                            {property.details && property.details.category
                              ? property.details.category === "Others"
                                ? property.details.customCategory
                                : property.details.category
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Phase</th>
                          {/* <td>{property.phase}</td> */}
                          <td>{property.phase ? property.phase : "-"}</td>
                          <th>Sector</th>
                          {/* <td>{property.sector}</td> */}
                          <td>{property.sector ? property.sector : "-"} </td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          {/* <td>{property.details && property.details.status}</td> */}
                          <td>
                            {property.details && property.details.status
                              ? property.details.status === "Others"
                                ? property.details.customStatus
                                : property.details.status
                              : "-"}
                          </td>

                          <th>Extra Land</th>
                          <td>
                            {/* {property.details && property.details.extraland} */}
                            {property.details && property.details.extraland
                              ? property.details.extraland
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          {/* <td>{property.details && property.details.category ? property.details.category : "-" }</td> */}
                          <td>Commercial</td>
                          <th>Published</th>
                          <td>{new Date(property.date).toDateString()}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Row>

                {property.details && property.details.description && (
                  <div
                    className="container"
                    style={{ marginBottom: "1em", backgroundColor: "#e8e9ee" }}
                  >
                    <h3
                      style={{
                        fontWeight: "600",
                        color: "#1A2B56",
                        // marginLeft: "1em",
                        // paddingBottom: "1em",
                        borderBottom: "1px solid black",
                      }}
                    >
                      Property Description
                    </h3>
                    <p
                      style={{
                        color: "#1A2B56",
                        // marginLeft: "1.6em"
                      }}
                    >
                      {property.details && property.details.description}
                    </p>
                  </div>
                )}

                <Row>
                  <CallToActionV6 property={property} />
                </Row>

                {/* <p style={{ color: "grey" }}>{property.description}</p> */}
              </Col>
              <Col xl={4} lg={4} md={4} xs={12}>
                <div
                  className=" text-center"
                  style={{
                    // width: "20rem",
                    backgroundColor: "#e8e9ee",

                    // height: "20rem",
                  }}
                >
                  <div>
                    <div className="ltn__feature-item ltn__feature-item-6 text-center active">
                      <div className=" ltn__service-btn go-top">
                        <h1
                          style={{
                            marginTop: "-1em",
                            fontWeight: "600",
                            fontSize: "1.5em",
                            color: "#1A2B56",
                            // color: "rgb(255, 90, 60)",
                          }}
                        >
                          Contact Information
                          {/* PKR {property.price} */}
                        </h1>
                        <hr
                          style={{
                            // width: "auto",
                            color: "#1A2B56",
                            fontWeight: "bold",
                            marginTop: "-0.1rem",
                            borderWidth: "2px",
                            borderColor: "#1A2B56",
                            // width: "19.9rem",
                            // marginLeft: "-2em",
                          }}
                        />
                      </div>

                      <div className="" style={{ width: "100%" }}>
                        <a
                          style={{
                            marginTop: "-2rem",
                            borderRadius: "5px",
                            // width: "100%",
                            // width: "70%",
                            letterSpacing: "2px",
                            width: "auto",
                            whiteSpace: "nowrap",
                          }}
                          href={
                            "tel:" +
                            ((property.details && property.details.callnow) ||
                              "")
                          }
                          className="theme-btn-call btn"
                        >
                          <strong>
                            {(
                              (property.details && property.details.callnow) ||
                              ""
                            ).replace(/(\d{4})(\d+)/, "$1-$2")}
                          </strong>
                        </a>
                      </div>

                      <div className="  mt-3" style={{ width: "100%" }}>
                        {/* <a
                        style={{
                          borderRadius: "5px",
                          // color: "#ff5a3c",
                          // backgroundColor: "white",
                          // borderWidth: "1px",
                          // borderColor: "#ff5a3c",
                          width: "70%",
                        }}
                        href={
                          "sms:" +
                          (property.details && property.details.callnow)
                        }
                        className="theme-btn-message btn "
                      >
                        <strong>SEND MESSAGE</strong>
                      </a> */}
                        <a
                          style={{
                            borderRadius: "5px",
                            // color: "#ff5a3c",
                            // backgroundColor: "white",
                            // borderWidth: "1px",
                            // borderColor: "#ff5a3c",
                            // width: "70%",
                            width: "auto",
                            whiteSpace: "nowrap",
                            letterSpacing: "2px",
                            // borderRadius: "5px",
                            // width: "2em",
                            // height: "1.8em",
                            // backgroundColor: "#25D366", // WhatsApp green color
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                          }}
                          href={`https://wa.me/${phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="theme-btn-Whatsapp btn"
                        >
                          <FaWhatsapp
                            style={{ color: "#25D366", marginRight: "0.2em" }}
                          />
                          <strong>WhatsApp</strong>
                        </a>
                      </div>

                      {/* <div className="  mt-3" style={{ width: "100%" }}>
                      <a
                        style={{
                          borderRadius: "5px",
                          width: "70%",
                          letterSpacing: "2px",
                        }}
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          window.location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="theme-btn-facebook btn"
                      >
                        <FaFacebook
                          style={{ color: "#1877f2", marginRight: "0.2em" }}
                        />
                        <strong>Facebook</strong>
                      </a>

                      <a
                        style={{
                          borderRadius: "5px",
                          width: "70%",
                          letterSpacing: "2px",
                        }}
                        href={`https://wa.me/?text=${encodeURIComponent(
                          window.location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="theme-btn-Whatsapp btn"
                      >
                        <FaWhatsapp
                          style={{ color: "#25D366", marginRight: "0.2em" }}
                        />
                        <strong>WhatsApp</strong>
                      </a>

                      <a
                        style={{
                          borderRadius: "5px",
                          width: "70%",
                          letterSpacing: "2px",
                        }}
                        href={`https://www.instagram.com/share?url=${encodeURIComponent(
                          window.location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="theme-btn-instagram btn"
                      >
                        <FaInstagram
                          style={{ color: "#bc2a8d", marginRight: "0.2em" }}
                        />
                        <strong>Instagram</strong>
                      </a>
                    </div> */}

                      <div className=" ltn__service-btn go-top">
                        <h1
                          style={{
                            marginTop: "1em",
                            marginBottom: "0.2em",
                            fontWeight: "600",
                            fontSize: "1.5em",
                            color: "#1A2B56",
                            borderBottom: "1px solid #1A2B56",
                            // color: "rgb(255, 90, 60)",
                          }}
                        >
                          Share Information
                          {/* PKR {property.price} */}
                        </h1>
                      </div>

                      <div className="d-flex" style={{ width: "100%" }}>
                        {/* <a
                        href="https://www.facebook.com/sharer/sharer.php?u=https://friendly.ge/en/activity/304802/explore-old-tbilisi-private-walking-tour&amp;title=Check this out!"
                        target="_blank"
                        rel="noopener"
                        className="facebook"
                      >
                        <FaFacebook
                      style={{ color: "#1877f2", fontSize: "2.5em" }}
                    />
                      </a> */}

                        <a
                          style={{
                            flex: "1",
                            borderRadius: "5px",
                            // marginRight: "5px",
                            letterSpacing: "2px",
                            padding: 0,
                            // height:"3em"
                            // fontSize: "2.5em",
                          }}
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                            window.location.href
                            // `https://www.propex.pk/plot-details-houses/994`
                          )}`}
                          // href={`https://www.facebook.com/dialog/feed?app_id=YOUR_APP_ID&display=popup&link=${encodeURIComponent}(
                          //   window.open(facebookDialogUrl, "Facebook Share", "width=600,height=400,menubar=no,toolbar=no,resizable=yes,scrollbars=yes");
                          // )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="theme-btn-facebook btn"
                        >
                          <FaFacebook
                            style={{ color: "#1877f2", fontSize: "2.5em" }}
                          />
                          {/* <strong>Facebook</strong> */}
                        </a>

                        <a
                          style={{
                            flex: "1",
                            borderRadius: "5px",
                            padding: 0,
                            letterSpacing: "2px",
                          }}
                          // href={`https://wa.me/?text=${encodeURIComponent(
                          //   `Hey, Check out this property I saw in DHA ${property.phase} in ${property.sector} in ${property.city} on Propex.pk\n\n${window.location.href}`
                          // )}`}
                          href={`https://wa.me/?text=${encodeURIComponent(
                            `Hey, Check out this property I saw on Propex.pk
                            \nProperty Details:
Phase:           ${property.phase === "DHA Valley" ? "" : "DHA "}${property.phase}
Sector:          ${property.sector ? property.sector : "-"}
Street:           ${property.details && property.details.streetTitle ? property.details.streetTitle : "-"}
Type:             Commercial
City:               ${property.city}
Category:      ${
                                                        property.details && property.details.category
                                                          ? property.details.category === "Others"
                                                            ? property.details.customCategory
                                                            : property.details.category
                                                          : "-"
                                                      }
Size:              ${property.size}
Price:             ${property.price}\n\nFor more details\n${
                                                        window.location.href
                                                      }`
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="theme-btn-Whatsapp1 btn"
                        >
                          <FaWhatsapp
                            style={{ color: "#25D366", fontSize: "2.5em" }}
                          />
                          {/* <strong>WhatsApp</strong> */}
                        </a>

                        {/* <a
                        style={{
                          flex: "1",
                          borderRadius: "5px",
                          padding: 0,
                          // marginRight: "5px",
                          letterSpacing: "2px",
                        }}
                        href={`https://wa.me/?text=${encodeURIComponent(
                          window.location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="theme-btn-Whatsapp1 btn"
                      >
                        <FaWhatsapp
                          style={{ color: "#25D366", fontSize: "2.5em" }}
                        />
                      </a> */}
                        {/* <strong>WhatsApp</strong> */}

                        {/* <a
                        style={{
                          flex: "1",
                          borderRadius: "5px",
                          padding: 0,
                          letterSpacing: "2px",
                        }}
                        href={`https://www.instagram.com/share?url=${encodeURIComponent(
                          window.location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="theme-btn-instagram btn"
                      >
                        <FaInstagram
                          style={{ color: "#bc2a8d", fontSize: "2.5em" }}
                        />
                        <strong>Instagram</strong>
                      </a> */}

                        <a
                          style={{
                            flex: "1",
                            borderRadius: "5px",
                            padding: 0,
                            letterSpacing: "2px",
                          }}
                          // href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                          //   window.location.href
                          // )}`}
                          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                            `Hey, Check out this property I saw on Propex.pk\n\nProperty Details:\nPhase: DHA ${
                              property.phase
                            }\nSector: ${property.sector}\nCity: ${
                              property.city
                            }\nCategory: ${
                              property.details && property.details.category
                                ? property.details.category === "Others"
                                  ? property.details.customCategory
                                  : property.details.category
                                : "-"
                            }\nSize: ${property.size}\nPrice: ${
                              property.price
                            }\n\n${window.location.href}`
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="theme-btn-twitter btn"
                        >
                          <FaTwitter
                            style={{ color: "#1DA1F2", fontSize: "2.5em" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className=" mt-5 "
                  style={{
                    // width: "20rem",
                    backgroundColor: "#d1cfcfa6",
                    // height: "20rem",
                  }}
                >
                  <div>
                    <div className="ltn__feature-item ltn__feature-item-6  active">
                      <div className=" ltn__service-btn go-top">
                        <h3
                          className="text-center"
                          style={{
                            fontWeight: "1000",
                            marginTop: "-1em",
                            color: "#1A2B56",
                            fontSize: "1.5em",
                          }}
                        >
                          Agent Information
                        </h3>
                        <hr
                          style={{
                            // width: "auto",
                            color: "#1A2B56",
                            fontWeight: "bold",
                            marginTop: "-0.1rem",
                            borderWidth: "2px",
                            borderColor: "#1A2B56",
                            // width: "19.9rem",
                            // marginLeft: "-2em",
                          }}
                        />
                        <Row>
                          {property.details && property.details.image ? (
                            <Col lg={4} md={4} sm={4} xs={4}>
                              <div
                                style={{
                                  marginTop: "-2.3em",
                                  width: "5em",
                                  height: "5em",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  display: "inline-block",
                                  marginBottom: "10px",
                                  position: "relative",
                                }}
                              >
                                {file && (
                                  <img
                                    src={file}
                                    alt="Agent"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {/* <img
                                src={file}
                                alt="Agent"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              /> */}
                              </div>
                            </Col>
                          ) : (
                            <Col
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              style={{
                                marginTop: "-0.8em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "2.5em",
                              }}
                            >
                              <FaUserAlt /> <br />
                            </Col>
                          )}
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <div
                              style={{ textAlign: "center", marginTop: "-2em" }}
                            ></div>
                            <b
                              style={{
                                fontWeight: "bold",
                                fontSize: "1em",
                                textAlign: "center",
                                position: "relative",
                              }}
                            >
                              Name:{" "}
                              {property.details && property.details.agentName}
                            </b>
                            <br />
                            <b
                              style={{
                                fontWeight: "bold",
                                fontSize: "1em",
                                textAlign: "center",
                              }}
                            >
                              Agency:{" "}
                              {/* {property.details && property.details.agentAgency} */}
                              {property.details
                                ? property.details.agentAgency
                                  ? property.details.agentAgency
                                  : property.details.agentDesignation
                                : null}
                            </b>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <SidebarV1 />
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div>Data Not Found</div>
        )}
      </div>
    </div>
  );
}

export default ShopDetailsCommercial;
