import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      blogs: [],
    };
  }
  componentDidMount() {
    // fetch("https://fortune.aiodock.com/fortune/blog/three").then((result) => {
    fetch("https://property.aiodock.com/fortune/blog/three").then((result) => {
      result.json().then((response) => {
        this.setState({ blogs: response });
      });
    });
  }
  render() {
    let anchor = "#";
    let imagealt = "image";
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div className="col-lg-4">
        <aside className="sidebar-area">
          <div className="widget widget-news">
            <h3 className="widget-title" style={{textAlign:'center'}}>Recent Blogs</h3>
            {this.state.blogs.map((blog, index) => {
              return <SingleBlog blog={blog} />;
            })}
          </div>
        </aside>
      </div>
    );
  }
}

function SingleBlog(props) {
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");

  useEffect(() => {
    fetch(
      // "https://fortune.aiodock.com/fortune/blog/image/" + props.blog.image,
      "https://property.aiodock.com/fortune/blog/image/" + props.blog.image,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.blog.datatype + "base64," + response.data);
        setExtension(props.blog.image.split(".")[1]);
      });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className="single-news-wrap media" >
      <div className="thumb">
        <img src={file} alt="img" />
      </div>
      <div className="media-body align-self-center">
        <h6>
          {" "}
          <Link
            className="read-more"
            key={props.blog._id}
            // to={`/blog-details/${props.blog.id}`}
            
            onClick={() => {
              window.location.href = `/blog-details/${props.blog.id}`;
            }}
          >
            {props.blog.title}
          </Link>
        </h6>
        <p className="date">
          <i className="far fa-calendar-alt" />
          {new Date(props.blog.date).toDateString()}
        </p>
      </div>
    </div>
  );
}

export default Sidebar;
