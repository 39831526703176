import React, { Component } from "react";
import "@fontsource/montserrat";
// import './fonts/Montserrat-Bold.ttf'
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import HomeV1 from "./components/home-v1";

import Location from "./components/location";
import Consultancy from "./components/consultancy";
import ShopGrid from "./components/plots-residentials";
import ShopGridcommercials from "./components/plots-commercials";
import ShopGridHousesSale from "./components/plots-houses-sale";
import ShopGridFlatsSale from "./components/plots-flats-sale";
import ShopGridhousesRent from "./components/plots-houses-rent";
import ShopGridflatsRent from "./components/plots-flats-rent";
import ProductDetailsCommercial from "./components/product-details-commercial";
import ProductDetailsResidential from "./components/product-details-residential";
import ProductDetailsHousesSale from "./components/product-details-houses-sale";
import ProductDetailsFlatsSale from "./components/product-details-flats-sale";
import ProductDetailsHousesRent from "./components/product-details-houses-rent";
import ProductDetailsFlatsRent from "./components/product-details-flats-rent";
import BlogGrid from "./components/blog-grid";
import BlogLeftSidebar from "./components/blog-left-sidebar";
import BlogRightSidebar from "./components/blog-right-sidebar";
import Blog from "./components/blog";
import BlogDetails from "./components/blog-details";
import Contact from "./components/contact";
import propertyGhupshup from "./components/propertyGhupshup";
import MarketWatch from "./components/MarketWatch";
import Map from "./components/dhaMap";
import ScrollToTop from "./components/scroll-to-top";
// import { render } from "react-snapshot";
import { HelmetProvider } from 'react-helmet-async';

import './app.global.css'
import documents_v1 from "./components/documents-v1";
class Root extends Component {
  render() {
    return (
      
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomeV1} />

          <Route path="/propertyGupshup" component={propertyGhupshup} />
          <Route path="/marketwatch" component={MarketWatch} />
          <Route path="/map" component={Map} />

          <Route path="/location" component={Location} />
          <Route path="/residentials" component={ShopGrid} />
          <Route path="/commercials" component={ShopGridcommercials} />

          <Route path="/housesForSale" component={ShopGridHousesSale} />
          <Route path="/flatsForSale" component={ShopGridFlatsSale} />

          <Route path="/housesForRent" component={ShopGridhousesRent} />
          <Route path="/flatsForRent" component={ShopGridflatsRent} />
          {/* <Route path="/product-details/:id" component={ProdductDetails} /> */}
          <Route
            path="/plot-details-residential/:id"
            component={ProductDetailsResidential}
          />
          <Route
            path="/plot-details-commercial/:id"
            component={ProductDetailsCommercial}
          />
          <Route
            path="/plot-details-houses/:id"
            component={ProductDetailsHousesSale}
          />
          <Route
            path="/plot-details-flats/:id"
            component={ProductDetailsFlatsSale}
          />
          <Route
            path="/plot-details-houses/:id"
            component={ProductDetailsHousesRent}
          />
          <Route
            path="/plot-details-flats/:id"
            component={ProductDetailsFlatsRent}
          />

          {/* documents */}
          <Route path="/documents" component={documents_v1} />
          
          
          {/* blog */}
          <Route path="/blogs" component={BlogGrid} />
          <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
          <Route path="/blog-right-sidebar" component={BlogRightSidebar} />
          <Route path="/blog" component={Blog} />
          <Route path="/blog-details/:id" component={BlogDetails} />

          <Route path="/contact" component={Contact} />
          <Route path="/consultancy" component={Consultancy} />
        </Switch>
      </Router>
      
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
// render(<Root />, document.getElementById("quarter"));
// ReactDOM.render(render(<Root />, document.getElementById("quarter")));