import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ContactInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__contact-address-area">
      {/* <div className="ltn__contact-address-area mb-90"> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <a href="mailto:+fortuneinvgroup@gmail.com">
                  <div className="ltn__contact-address-icon">
                    <img
                      src={publicUrl + "assets/img/icons/10.png"}
                      alt="Icon Image"
                    />
                  </div>
                  <h3>Email Address</h3>
                  <p style={{ color: "grey" }}>info@propex.pk</p>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <a href="tel:+923331117177">
                  <div className="ltn__contact-address-icon">
                    <img
                      src={publicUrl + "assets/img/icons/11.png"}
                      alt="Icon Image"
                    />
                  </div>{" "}
                  <h3>Phone Number</h3>
                  <p style={{ color: "grey" }}>
                    +92 333 1117177
                    <br />{" "}
                  </p>{" "}
                </a>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <a
                  href="https://goo.gl/maps/XmESwZ4L2gTEDdmd8"
                  target="_blank"
                >
                  <div className="ltn__contact-address-icon">
                    <img
                      src={publicUrl + "assets/img/icons/12.png"}
                      alt="Icon Image"
                    />
                  </div>
                  <h3>Office Address</h3>
                  <p style={{ color: "grey" }}>
                    PropEx , Islamabad, Pakistan
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
