import React,  { useEffect } from "react";
import Navbar from "./global-components/navbar-v3";
import BlogDetails from "./blog-components/blog-details";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const BlogDetailsPage = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  

  return (
    <div>
      <Navbar />
      <div
        style={{ height: "", marginBottom: "50px" }}
        className="bg-overlay-theme-black-100 navbar-image ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image "
        // data-bs-bg={publicUrl + "assets/img/blogs2.jpg"} 
        data-bs-bg={`https://property.aiodock.com/fortune/website/Blogs.png`}
      ></div>
      <BlogDetails />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};


export default BlogDetailsPage;
