import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Social from "../section-components/social";
import FloatingWhatsApp from "react-floating-whatsapp";
import dp from "./propex.png";
import { pulse } from "react-animations";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  Pulse: {
    animationName: pulse,
    animationDuration: "1s",
    animationIterationCount: "infinite",
  },
});

class NavbarV3 extends Component {
  handleLinkClick = () => {
    // Clear the local storage here
    localStorage.clear();
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
      // isCrossClicked: false,
    };
  }
  // openMobileMenu = () => {
  //   this.setState({ isMobileMenuOpen: true });
  // };

  // closeMobileMenu = () => {
  //   this.setState({ isMobileMenuOpen: false });
  // };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    return (
      <div>
        <div
          className="my-custom-class"
          style={{ position: "absolute", zIndex: 10 }}
        >
          <FloatingWhatsApp
            phoneNumber="+92 333 1117177"
            accountName="Propex"
            chatMessage={`Hey there ! I am your real estate agent. How May I help you ?`}
            allowEsc
            notification
            style={{ height: "100%" }}
            avatar={dp}
          />
        </div>
        <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4---">
          {/* To Stick website use these classes 
          {ltn__header-sticky ltn__sticky-bg-black} */}
          <div className="ltn__header-middle-area ">
            <div className="container">
              <div className="row">
                <div className="col--- ltn__header-options ltn__header-options-2">
                  {/* Mobile Menu Button */}
                  <div className="mobile-menu-toggle d-xl-none">
                    <a
                      href="#ltn__utilize-mobile-menu"
                      // className="ltn__utilize-toggle "
                      // onClick={() => {
                      //   if (this.state.isMobileMenuOpen) {
                      //     this.closeMobileMenu();
                      //   } else {
                      //     this.openMobileMenu();
                      //   }
                      // }}

                      // className={`ltn__utilize-toggle ${
                      //   this.state.isMobileMenuOpen ? "ltn__utilize-open" : ""
                      // }`}
                      // onClick={() => {
                      //   if (this.state.isMobileMenuOpen) {
                      //     this.closeMobileMenu();
                      //   } else {
                      //     this.openMobileMenu();
                      //   }
                      // }}

                      className={`ltn__utilize-toggle ${
                        this.state.isMobileMenuOpen ? "ltn__utilize-open" : ""
                      }`}
                      onClick={this.toggleMobileMenu}
                    >
                      {/* <i class='bx bx-menu' id="header-toggle"></i>  */}
                      <svg viewBox="0 0 800 600">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />

                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="col">
                  <div className="site-logo-wrap">
                    <div className="site-logo go-top">
                      <Link to="/">
                        <img
                          src={publicUrl + "assets/img/propex logo-home.png"}
                          alt="Logo"
                        />
                      </Link>
                    </div>
                    <div className="get-support clearfix d-none">
                      <div className="get-support-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="get-support-info">
                        <h6>Get Support</h6>
                        <h4>
                          <a href="tel:+123456789">123-456-789-10</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col header-menu-column menu-color-white">
                  <div className="header-menu d-none d-xl-block go-top">
                    <nav>
                      <div className="ltn__main-menu go-top">
                        <ul>
                          <li>
                            <NavLink
                              to="/residentials"
                              onClick={this.handleLinkClick}
                              activeClassName="active-link"
                            >
                              Residential
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/commercials"
                              onClick={this.handleLinkClick}
                              activeClassName="active-link"
                            >
                              Commercial
                            </NavLink>
                          </li>
                          <li className="menu-icon">
                            <NavLink
                              to="/housesForRent"
                              onClick={this.handleLinkClick}
                              activeClassName="active-link"
                              isActive={(match, location) => {
                                const isHouses =
                                  location.pathname.includes("/houses");
                                return isHouses;
                              }}
                            >
                              Houses
                            </NavLink>
                            <ul className="sub-menu menu-pages-img-show">
                              <li>
                                <NavLink
                                  to="/housesForRent"
                                  onClick={this.handleLinkClick}
                                  activeClassName="active-link"
                                >
                                  Rental
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/housesForSale"
                                  onClick={this.handleLinkClick}
                                  activeClassName="active-link"
                                >
                                  Sale
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          {/* <li>
                            <NavLink
                              to="/houses"
                              onClick={this.handleLinkClick}
                              activeClassName="active-link"
                            >
                              Houses
                            </NavLink>
                          </li> */}
                          <li className="menu-icon">
                            <NavLink
                              to="/flatsForRent"
                              onClick={this.handleLinkClick}
                              activeClassName="active-link"
                              isActive={(match, location) => {
                                const isHouses =
                                  location.pathname.includes("/flats");
                                return isHouses;
                              }}
                            >
                              Flats
                            </NavLink>
                            <ul className="sub-menu menu-pages-img-show">
                              <li>
                                <NavLink
                                  to="/flatsForRent"
                                  onClick={this.handleLinkClick}
                                  activeClassName="active-link"
                                >
                                  Rental
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/flatsForSale"
                                  onClick={this.handleLinkClick}
                                  activeClassName="active-link"
                                >
                                  Sale
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <NavLink
                              to="/propertyGupshup"
                              activeClassName="active-link"
                            >
                              Property Gupshup
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/map" activeClassName="active-link">
                              Maps
                            </NavLink>
                          </li>
                          {/* <li>
                            <Link to="/marketwatch">Market Watch</Link>
                          </li> */}
                          {/* <li>
                              <Link to="/consultancy">Consultancy</Link>
                            </li> */}
                          <li>
                            <NavLink to="/blogs" activeClassName="active-link">
                              Blogs
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/documents"
                              activeClassName="active-link"
                            >
                              Documents
                            </NavLink>
                          </li>
                          {/* <li className={css(styles.Pulse)}> */}
                          <li>
                            <NavLink to="/contact">Contact Us</NavLink>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          id="ltn__utilize-mobile-menu"
          // className="ltn__utilize ltn__utilize-mobile-menu"
          className={`ltn__utilize ltn__utilize-mobile-menu ${
            this.state.isMobileMenuOpen ? "ltn__utilize-open" : ""
          }`}
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <div className="site-logo">
                <Link to="/">
                  <img src={publicUrl + "assets/img/propex.png"} alt="Logo" />
                </Link>
              </div>
              <button
                className="ltn__utilize-close"
                onClick={this.toggleMobileMenu}
              >
                ×
              </button>
            </div>
            <div className="ltn__utilize-menu " style={{ color: "grey" }}>
              <ul>
                <li>
                  <NavLink
                    to="/residentials"
                    onClick={this.handleLinkClick}
                    activeClassName="active-link"
                  >
                    Residential
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/commercials"
                    onClick={this.handleLinkClick}
                    activeClassName="active-link"
                  >
                    Commercial
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/houses"
                    onClick={this.handleLinkClick}
                    activeClassName="active-link"
                  >
                    Houses
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/flats"
                    onClick={this.handleLinkClick}
                    activeClassName="active-link"
                  >
                    Flats
                  </NavLink>
                </li> */}
                <li className="">
                  <NavLink
                    // to="/houses"
                    to="/housesForRent"
                    onClick={this.handleLinkClick}
                    activeClassName="active-link"
                  >
                    Houses
                  </NavLink>
                  <ul className="sub-menu menu-pages-img-show">
                    <li>
                      <NavLink
                        to="/housesForRent"
                        onClick={this.handleLinkClick}
                        activeClassName="active-link"
                      >
                        Rental
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/housesForSale"
                        onClick={this.handleLinkClick}
                        activeClassName="active-link"
                      >
                        Sale
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* <li>
                            <NavLink
                              to="/houses"
                              onClick={this.handleLinkClick}
                              activeClassName="active-link"
                            >
                              Houses
                            </NavLink>
                          </li> */}
                <li className="">
                  {/* <a href="#">Flats</a> */}
                  <NavLink
                    to="/flatsForRent"
                    onClick={this.handleLinkClick}
                    activeClassName="active-link"
                  >
                    Flats
                  </NavLink>
                  <ul className="sub-menu menu-pages-img-show">
                    <li>
                      <NavLink
                        to="/flatsForRent"
                        onClick={this.handleLinkClick}
                        activeClassName="active-link"
                      >
                        Rental
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/flatsForSale"
                        onClick={this.handleLinkClick}
                        activeClassName="active-link"
                      >
                        Sale
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="/propertyGupshup" activeClassName="active-link">
                    Property Gupshup
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/map" activeClassName="active-link">
                    Maps
                  </NavLink>
                </li>
                {/* <li>
                  <Link to="/marketwatch">Market Watch</Link>
                </li> */}
                {/* <li>
                  <Link to="/consultancy">Consultancy</Link>
                </li> */}
                <li>
                  <NavLink to="/blogs" activeClassName="active-link">
                    Blogs
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/documents" activeClassName="active-link">
                    Documents
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavbarV3;
