import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Reveal from "react-reveal/Reveal";
class BannerV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__slider-area ltn__slider-3" 
      // style={{marginTop:"-3em"}}
      >
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
          {/* ltn__slide-item */}

          <div className="ltn__slide-item-inner text-center">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 align-self-center">
                  <div
                    className="slide-item-info">
                    <div className="slide-item-info-inner ltn__slide-animation">
                      {/* <div className="slide-video mb-50 d-none"> */}
                      <div className="slide-video d-none">
                        <a
                          className="ltn__video-icon-2 ltn__video-icon-2-border"
                          href="https://www.youtube.com/embed/Yo4fHHkukos"
                          data-rel="lightcase:myCollection"
                        >
                          <i className="fa fa-play" />
                        </a>
                      </div>
                      {/* <Reveal effect="fadeInUp"  duration={2000}>

                        <h6 className="slide-sub-title white-color">
                          <span>
                            <i className="fas fa-home" />
                          </span>{" "}
                          Real Estate Agency
                        </h6>
                        </Reveal> */}
                      <Reveal effect="fadeInUp" duration={4000}>
                        {/* <h1 className="slide-title animated ">
                          Find Your Dream <br /> House By Us
                        </h1> */}

                        <div
                          className="section-title"
                          // style={{marginTop: "-30px"}}
                          // style={{ marginTop: "-5px" }}
                        >
                          {/* <div className="section-title mt-5"> */}
                          <h1
                            style={{
                              color: "white",
                              fontWeight: "800",
                              fontSize: "36px",
                              textAlign: "center",
                              // marginTop:"auto",
                              
                              // alignItems: "center",
                              // justifyContent:"center",
                              // display: "flex",
                              // flexDirection:"column",
                              // position: "relative",
                              // zIndex:"10",
                              // marginTop:"50px"
                            }}
                          >
                            YOUR ULTIMATE DHA EXCHANGE
                          </h1>
                        </div>

                        <Reveal effect="fadeInUp" duration={8000}></Reveal>
                        <div className="btn-wrapper go-top" 
                        style={{marginTop:"-0.5em"}}
                        >
                          <Link
                            style={{ borderRadius: "5px" }}
                            to="/residentials"
                            // className="theme-btn-1 btn btn-effect-1"
                            className="theme-btn-1 btn"
                          >
                            <strong>CHECK PLOTS HERE</strong>
                          </Link>
                        </div>
                      </Reveal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerV3;
