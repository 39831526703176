import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import ReactPaginate from "react-paginate";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Row, Col } from "reactstrap";
import Loader from "../Loader/Loader";
import {
  // Button,
  Card,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import SidebarV1 from "../blog-components/sidebar-v2";
import styled from "styled-components";
import Select from "react-select";

import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import Box from "@mui/material/Box";
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSquareXmark,
  faXmark,
  faAngleRight,
  faMap,
  faLocationDot,
  faPenRuler,
} from "@fortawesome/free-solid-svg-icons";

const FilterContext = React.createContext();

function ShopGridV1() {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const location = useLocation();

  const [selectedPhase, setSelectedPhase] = useState("All Phase");
  const [selectedSector, setSelectedSector] = useState("All Sector");
  const [selectedSize, setSelectedSize] = useState("All Size");
  const [myLoader, setMyLoader] = useState(false);
  const [sizes, setSizes] = React.useState([
    // "2 Marla",
    // "4 Marla",
    // "6 Marla",
    // "8 Marla",
    // "10 Marla",

    "All Size",
    "1 Marla",
    "2 Marla",
    "3 Marla",
    "4 Marla",
    "5 Marla",
    "5.33 Marla",
    "6 Marla",
    "7 Marla",
    "8 Marla",
    "9 Marla",
    "10 Marla",
    "11 Marla",
    "12 Marla",
    "13 Marla",
    "14 Marla",
    "15 Marla",
    "16 Marla",
    "17 Marla",
    "18 Marla",
    "19 Marla",
    "20 Marla",
    "1 Kanal",
    "2 Kanal",
    "3 Kanal",
    "4 Kanal",
    "5 Kanal",
  ]);

  const [allphases, setAllPhases] = React.useState([
    "All Phase",
    "Phase 1",
    "Phase 2",
    "Phase 3",
    "Phase 4",
    "Phase 5",
    "Phase 6",
    "DHA Valley",
  ]);

  const [loadSector, setloadSector] = useState({
    "Sector A": ["1 Marla", "2 Marla", "3 Marla"],
    "Sector B": ["1 Marla", "5 Marla", "6 Marla"],
    "Sector C": ["1 Marla", "3 Marla", "8 Marla"],
  });

  const [loadPhase, setloadPhase] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subDrawerOpen, setSubDrawerOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleSubDrawer = () => {
    setSubDrawerOpen(!subDrawerOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (
      option === "Phase Select" ||
      option === "Sector Select" ||
      option === "Size Select"
    ) {
      toggleSubDrawer();
    } else {
      setSubDrawerOpen(false);
    }
  };

  // const [dropdownOpenphase, setDropdownOpenphase] = useState(false);
  // const toggle = () => setDropdownOpenphase((prevState) => !prevState);

  // const [dropdownOpensector, setDropdownOpensector] = useState(false);
  // const toggleSector = () => setDropdownOpensector((prevState) => !prevState);

  // const [dropdownOpensize, setDropdownOpensize] = useState(false);
  // const toggleSize = () => setDropdownOpensize((prevState) => !prevState);

  const [searchSize, setSearchSize] = useState("");

  const filteredSizes =
    selectedSector === "All Sector"
      ? sizes.filter((size) =>
          size.toLowerCase().includes(searchSize.toLowerCase())
        )
      : loadSector[selectedSector]?.filter((size) =>
          size.toLowerCase().includes(searchSize.toLowerCase())
        );

  const handlePhaseChange = (selectedOption) => {
    // Check if there is a stored phase in localStorage
    const storedFilterState = localStorage.getItem("filterCommercialState");
    const storedFilterStateObject = storedFilterState
      ? JSON.parse(storedFilterState)
      : {};
    const selectedPhase = selectedOption.value;

    setSelectedPhase(selectedPhase);
    localStorage.removeItem("filterCommercialState");
    localStorage.setItem("selectedPhase", selectedPhase);

    // Reset the selected sector to "All Sector" when the phase changes
    setSelectedSector("All Sector");

    // Use the stored selectedPhase from localStorage if it exists
    const storedSelectedPhase = storedFilterStateObject.selectedPhase;
    // console.log("Stored Phase---", storedSelectedPhase);

    var obj = {
      phase: storedSelectedPhase || selectedPhase,
    };

    fetch("https://property.aiodock.com/fortune/sector/phaseHavingSectors", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        var obj = {};
        var sector = [];
        if (Array.isArray(res)) {
          res.map((response) => {
            sector.push(response.sector);
            if (obj[response.sector] == undefined) {
              obj[response.sector] = [...response.plot];
            }
          });
        }
        setloadPhase(sector);
        setloadSector(obj);
        setSubDrawerOpen(false);

        // console.log("sector:", sector);
        // console.log("obj:", obj);
      });
  };

  // Define "All Sector" and "All Size" options
  const allSectorOption = {
    label: "All Sector",
    value: "All Sector",
  };

  const allSizeOption = {
    label: "All Size",
    value: "All Size",
  };

  // Include "All Sector" in sectorOptions
  const sectorOptions = [
    allSectorOption,
    ...loadPhase.map((sector) => ({
      label: sector,
      value: sector,
    })),
  ];

  // Include "All Size" in sizesOptions
  const sizesOptions = [
    // allSizeOption,
    ...filteredSizes.map((size) => ({
      label: size,
      value: size,
    })),
  ];

  const phaseOptions = allphases.map((phase) => ({
    label: phase,
    value: phase,
  }));

  const handleSectorChange = (selectedOption) => {
    setSelectedSector(selectedOption.value);
    // Reset the selected size to "All Size" when the sector changes
    setSelectedSize("All Size");
    // Fetch data with the new filter selections
    fetchFilteredData(selectedPhase, selectedOption.value, "All Size");
    setSubDrawerOpen(false);
  };

  const handleSizeChange = (selectedOption) => {
    setSelectedSize(selectedOption.value);
    // Fetch data with the new filter selections
    fetchFilteredData(selectedPhase, selectedSector, selectedSize);
    setSubDrawerOpen(false);
  };

  const CustomButton = styled.button`
    height: 1.4em; // 40px
    width: 99%;
    background-color: #ff5a3c;
    border-color: #ff5a3c;
    font-size: 2em;
    display: flex;
    border-radius: 5px;
    border: 2px solid black;
    align-items: center;
    justify-content: center;
    color: white;

    // @media screen and (min-width: 576px) {
    @media screen and (min-width: 901px) {
      height: 2.5em;
      width: 2.5em;
      border-radius: 50%;
      margin-left: -1.5em;
      border: 2px solid #ff6b50;
      // border: 2px solid white;
      // border: none;
    }
  `;

  const customStyles = {
    option: (base, state) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      border: "none", // Remove the border
      height: "3em",
      paddingBottom: "4em",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "left",
      position: "relative",
    }),

    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      padding: "0.5em",
      marginTop: "-1em",
      color: "black",
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      marginLeft: "10px",
      marginTop: "-1em",
      fontWeight: 600,
      color: "black",
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginLeft: "10px",
      marginTop: "-1em",
      fontWeight: 600,
      color: "black",
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: 0,
    }),
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [plot, setPlot] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [imageData, setImageData] = useState("");
  const [error, setError] = useState(null);

  //               Filter
  // Define the applyFilters function to filter data
  const applyFilters = (selectedPhase, selectedSector, selectedSize) => {
    setSelectedPhase(selectedPhase);
    setSelectedSector(selectedSector);
    setSelectedSize(selectedSize);

    // Store the filter state in local storage
    localStorage.setItem(
      "filterCommercialState",
      JSON.stringify({ selectedPhase, selectedSector, selectedSize })
    );

    // Filter the data based on the selected filters
    const filtered = plot.filter((value) => {
      if (
        searchTerm === "" &&
        selectedPhase === "All Phase" &&
        selectedSector === "All Sector" &&
        selectedSize === "All Size"
      ) {
        return value;
      } else {
        let matched = true;
        if (searchTerm !== "") {
          matched =
            matched &&
            value.title.toLowerCase().includes(searchTerm.toLowerCase());
        }
        if (selectedPhase !== "All Phase") {
          matched = matched && value.phase === selectedPhase;
        }
        if (selectedSize !== "All Size") {
          matched = matched && value.size === selectedSize;
        }
        if (selectedSector !== "All Sector") {
          matched = matched && value.sector === selectedSector;
        }
        return matched;
      }
    });

    setFilteredData(filtered);
    fetchFilteredData(selectedPhase, selectedSector, selectedSize);
  };

  // Handle the search button click
  const handleClick = (event) => {
    if (event) {
      event.preventDefault();
    }
    applyFilters(selectedPhase, selectedSector, selectedSize);

    // Step 1: Save filter settings to local storage
    localStorage.setItem(
      "filterCommercialState",
      JSON.stringify({ selectedPhase, selectedSector, selectedSize })
    );

    setDrawerOpen(false);
  };

  //               Images
  // const applyFiltersAutomatically = () => {
  // Load the saved filters from local storage
  // const storedFilterState = localStorage.getItem("filterState");
  // if (storedFilterState) {
  //   const { selectedPhase, selectedSector, selectedSize } =
  //     JSON.parse(storedFilterState);

  //   // Apply the saved filters when the page loads
  //   applyFilters(selectedPhase, selectedSector, selectedSize);
  // }

  //   const storedFilterState = localStorage.getItem("filterState");

  //   if (storedFilterState) {
  //     const { selectedPhase, selectedSector, selectedSize } =
  //       JSON.parse(storedFilterState);
  //     // Initialize your filter states here
  //     setSelectedPhase(selectedPhase);
  //     setSelectedSector(selectedSector);
  //     setSelectedSize(selectedSize);

  //     // Apply filters automatically
  //     // applyFilters(selectedPhase, selectedSector, selectedSize);
  //     // fetchFilteredData(selectedPhase, selectedSector, selectedSize);

  //     // Check if any of the filter values is "All Phase," "All Sector," or "All Size"

  //     if (
  //       selectedPhase === "All Phase" &&
  //       selectedSector === "All Sector" &&
  //       selectedSize === "All Size"
  //     ) {
  //       setSelectedPhase("All Phase");
  //       setSelectedSector("All Sector");
  //       setSelectedSize("All Size");

  //       fetchAllDataFromAPI();
  //     } else {
  //       applyFilters(selectedPhase, selectedSector, selectedSize);
  //       fetchFilteredData(selectedPhase, selectedSector, selectedSize);
  //     }
  //   } else {
  //     // Initialize the filter states with default values
  //     setSelectedPhase("All Phase");
  //     setSelectedSector("All Sector");
  //     setSelectedSize("All Size");

  //     // Fetch all data
  //     fetchAllDataFromAPI();
  //   }
  // };

  useEffect(() => {
    // Retrieve the selectedPhase from localStorage
    const storedSelectedPhase = localStorage.getItem("selectedPhase");

    if (storedSelectedPhase) {
      // If a selectedPhase is found in localStorage, use it
      setSelectedPhase(storedSelectedPhase);

      // Fetch the corresponding sectors based on the retrieved selectedPhase
      const obj = {
        phase: storedSelectedPhase,
      };

      fetch("https://property.aiodock.com/fortune/sector/phaseHavingSectors", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((res) => {
          var obj = {};
          var sector = [];
          if (Array.isArray(res)) {
            res.map((response) => {
              sector.push(response.sector);
              if (obj[response.sector] == undefined) {
                obj[response.sector] = [...response.plot];
              }
            });
          }
          setloadPhase(sector);
          setloadSector(obj);
        });
    } else {
      // If there's no selectedPhase in localStorage, you can set a default phase or handle it as per your app's requirements.
      setSelectedPhase("All Phase");

      // You can also fetch the corresponding sectors for the default phase here if needed.
    }
  }, [selectedPhase]); // Make sure to include any dependencies if needed

  useEffect(() => {
    //     // Fetch banner image and commercial plots
    // fetch("https://property.aiodock.com/fortune/website/pageImage/38.png")
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Image not found");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     setImageData(data.data);
    //   })
    //   .catch((error) => {
    //     console.error("Image fetch error:", error);
    //     setError(error.message);
    //   });

    // Set a timeout to simulate a delay of 2 seconds
    // setTimeout(() => {
    //   setIsLoading(false); // After 2 seconds, stop loading
    // }, 2000);

    const storedFilterState = localStorage.getItem("filterCommercialState");

    // if (storedFilterState) {
    //   const { selectedPhase, selectedSector, selectedSize } =
    //     JSON.parse(storedFilterState);

    //   // setSelectedPhase(selectedPhase);
    //   // setSelectedSector(selectedSector);
    //   // setSelectedSize(selectedSize);

    //   console.log("storedFilterState");
    //   console.log(selectedPhase);
    //   console.log(selectedSector);
    //   console.log(selectedSize);

    //   // Step 3: Apply the saved filters and fetch data from the API
    //   applyFilters(selectedPhase, selectedSector, selectedSize);
    //   fetchFilteredData(selectedPhase, selectedSector, selectedSize);
    // } else {
    //   // Step 4: If no filter settings found, fetch all data from the API
    //   // If no filter settings found, use default values
    //   setSelectedPhase("All Phase");
    //   setSelectedSector("All Sector");
    //   setSelectedSize("All Size");

    //   fetchAllDataFromAPI();
    // }

    if (storedFilterState) {
      const { selectedPhase, selectedSector, selectedSize } =
        JSON.parse(storedFilterState);

      // Initialize your filter states here
      setSelectedPhase(selectedPhase);
      setSelectedSector(selectedSector);
      setSelectedSize(selectedSize);

      // Apply filters automatically
      applyFilters(selectedPhase, selectedSector, selectedSize);
      // fetchFilteredData(selectedPhase, selectedSector, selectedSize);

      // Check if any of the filter values is "All Phase," "All Sector," or "All Size"

      if (
        selectedPhase === "All Phase" &&
        selectedSector === "All Sector" &&
        selectedSize === "All Size"
      ) {
        setSelectedPhase("All Phase");
        setSelectedSector("All Sector");
        setSelectedSize("All Size");

        fetchAllDataFromAPI();
      } else {
        setSelectedPhase(selectedPhase);
        setSelectedSector(selectedSector);
        setSelectedSize(selectedSize);

        applyFilters(selectedPhase, selectedSector, selectedSize);
        fetchFilteredData(selectedPhase, selectedSector, selectedSize);
      }
    } else {
      // Initialize the filter states with default values
      setSelectedPhase("All Phase");
      setSelectedSector("All Sector");
      setSelectedSize("All Size");

      // Fetch all data
      setMyLoader(true)
      fetchAllDataFromAPI();
    }
  }, []);
  // Fetch data from the API with filter parameters
  const fetchFilteredData = (phase, sector, size) => {
    // Fetch data from the API with filter parameters
    // Update your API endpoint with the filter parameters
    const apiUrl = `https://property.aiodock.com/fortune/plot/category/filter/commercial?phase=${phase}&sector=${sector}&size=${size}`;

    fetch(apiUrl)
      .then((result) => result.json())
      .then((response) => {
        setPlot(response);
        setFilteredData(response);
      })
      .catch((error) => {
        console.error("Plot fetch error:", error);
        setError(error.message);
      });
  };

  // Fetch all data from the API
  const fetchAllDataFromAPI = () => {
    // Fetch all data from the API without filter parameters
    fetch("https://property.aiodock.com/fortune/plot/category/commercial")
      .then((result) => result.json())
      .then((response) => {
        setPlot(response);
        // setFilteredData(response);
        setTimeout(() => {
        
          setFilteredData(response);
          setMyLoader(false)
          // setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Plot fetch error:", error);
        setError(error.message);
      });
  };

  // useEffect(() => {
  //   // Clear the local storage when the href changes
  //   localStorage.clear();
  // }, [location.pathname]);

  return (
    <div
      className="ltn__product-area"
      //    <div className="ltn_product-area ltn_product-gutter "
      style={{
        padding: 0,
        margin: 0,
        width: "100%",
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <div>
        <div className="row">
          <div className="tab-content ">
            {/* <div className="tab-content "> */}
            <div className="ltn_product-tab-content-inner ltn_product-grid-view">
              <div
                className="navbar-image ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image "
                // data-bs-bg={publicUrl + "assets/img/commercial.png"}
                // data-bs-bg={`data:image/png;base64,${imageData}`}
                data-bs-bg={`https://property.aiodock.com/fortune/website/Commercial.png`}
                style={{}}
              >
                <div className="container">
                  <h1
                    style={{
                      color: "white",
                      fontWeight: 800,
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    YOUR ULTIMATE DHA EXPERIENCE
                  </h1>
                  {/* Drop down menus */}
                  <BrowserView>
                    <FilterContext.Provider
                      value={{
                        selectedPhase,
                        selectedSector,
                        selectedSize,
                        // handleFilterChange,
                        applyFilters,
                      }}
                    >
                      <Row
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          margin: "0 auto",
                          marginTop: "0.5em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                          // border:'0.2em solid black'
                        }}
                      >
                        <Col xs={1} md={1} lg={1} />
                        <Col
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          style={{
                            padding: 0,
                            margin: 0,
                            alignItems: "center",
                            overflow: "visible",

                            // border: "0.2em solid",
                          }}
                        >
                          <div
                            className="short-by  text-center "
                            style={{
                              color: "grey",
                              position: "relative",
                              // marginRight: "0.25em",
                              marginBottom: "0.1em",
                              borderRadius: "5px",
                              height: "100%",
                              // border:"0.2em solid"
                              borderRight: "0.3em solid black",
                            }}
                          >
                            <Select
                              options={phaseOptions}
                              onChange={handlePhaseChange}
                              // placeholder="Select Phase"
                              placeholder={selectedPhase}
                              value={selectedPhase}
                              styles={customStyles}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          style={{
                            padding: 0,
                            margin: 0,
                            alignItems: "center",

                            overflow: "visible",
                            // border: "0.2em solid ",
                            // borderBottom: "0.2em solid ",
                          }}
                        >
                          <div
                            className="short-by  text-center "
                            style={{
                              color: "grey",
                              position: "relative",
                              // marginRight: "0.25em",
                              marginBottom: "0.1em",
                              borderRadius: "5px",
                              height: "100%",
                              // border:"0.2em solid"
                              borderRight: "0.3em solid black",
                            }}
                          >
                            <Select
                              options={sectorOptions}
                              onChange={handleSectorChange}
                              placeholder={selectedSector}
                              value={selectedSector}
                              styles={customStyles}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          style={{
                            padding: 0,
                            margin: 0,
                            alignItems: "center",
                            overflow: "visible",
                            // border: "0.2em solid ",
                          }}
                        >
                          <div
                            className="short-by  text-center "
                            style={{
                              color: "grey",
                              position: "relative",
                              // marginRight: "0.25em",
                              marginBottom: "0.1em",
                              borderRadius: "5px",
                              height: "100%",
                              // border:"0.2em solid"
                            }}
                          >
                            <Select
                              options={sizesOptions}
                              onChange={handleSizeChange}
                              placeholder={selectedSize}
                              value={selectedSize}
                              styles={customStyles}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={1} md={1} lg={1} />

                        <Col
                          xs={12}
                          sm={1}
                          md={1}
                          lg={1}
                          style={{
                            padding: 0,
                            margin: 0,
                            // marginLeft:"-3em"
                            // border: "1px solid ",
                            // maxWidth: "200px",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <CustomButton onClick={handleClick}>
                              <FaSearch />
                            </CustomButton>
                          </div>
                        </Col>
                      </Row>
                    </FilterContext.Provider>
                  </BrowserView>
                </div>
              </div>

              <div className="m-5" style={{ color: "grey" }}>
                <Row>
                  <Col sm={12} lg={12} md={12} xs={12}>
                    <div>
                      <FilterContext.Provider
                        value={{
                          selectedPhase,
                          selectedSector,
                          selectedSize,
                          // handleFilterChange,
                          applyFilters,
                        }}
                      >
                        <MobileView>
                          <div style={{ paddingTop: "5px" }}>
                            {[isMobile ? "bottom" : ""].map((anchor) => (
                              <React.Fragment key={anchor}>
                                <div
                                  style={{
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    backgroundColor: "#f0f0f0",
                                    marginBottom: "1em",
                                  }}
                                >
                                  <div>
                                    <h3
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      Currently Showing
                                    </h3>
                                  </div>
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "repeat(2, 1fr)",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ fontWeight: 600 }}>
                                      <h5>Selected Phase:</h5>
                                      <h5>Selected Sector:</h5>
                                      <h5>Selected Size:</h5>
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: 500,
                                        marginLeft: "1em",
                                      }}
                                    >
                                      <h5 style={{ color: "#ff3c5a" }}>
                                        {selectedPhase}
                                      </h5>
                                      <h5 style={{ color: "#ff3c5a" }}>
                                        {selectedSector}
                                      </h5>
                                      <h5 style={{ color: "#ff3c5a" }}>
                                        {selectedSize}
                                      </h5>
                                    </div>
                                  </div>
                                  <Button
                                    type="button"
                                    class="btn btn-primary"
                                    onClick={toggleDrawer}
                                    style={{ marginBottom: "0.5em" }}
                                  >
                                    Open Filter{" "}
                                    <FontAwesomeIcon icon={faFilter} />
                                  </Button>
                                </div>
                                <Drawer
                                  anchor="right"
                                  open={drawerOpen}
                                  onClose={toggleDrawer}
                                >
                                  <div style={{ width: 250 }}>
                                    <List className="Container">
                                      <ListItem>
                                        <ListItemText primary="Filter" />
                                        <Button
                                          // variant="outlined"
                                          color="error"
                                          onClick={toggleDrawer}
                                          style={{ marginLeft: "auto" }}
                                        >
                                          {/* <FontAwesomeIcon
                                            icon={faSquareXmark}
                                          /> */}

                                          <FontAwesomeIcon
                                            icon={faXmark}
                                            size="xl"
                                          />
                                        </Button>
                                      </ListItem>
                                      <Divider />
                                      <ListItem>
                                        {/* <FontAwesomeIcon icon={faMap} style={{color: "#ff3c5a",}} /> */}
                                        <ListItemText primary="Select Phase :" />
                                      </ListItem>
                                      <ListItem
                                        button
                                        onClick={() =>
                                          handleOptionClick("Phase Select")
                                        }
                                        options={phaseOptions}
                                        onChange={handlePhaseChange}
                                        style={{
                                          color: "#ff3c5a",
                                          marginTop: "-2em",
                                        }}
                                      >
                                        <ListItemText primary={selectedPhase} />
                                        <FontAwesomeIcon icon={faAngleRight} />
                                      </ListItem>

                                      <ListItem>
                                        {/* <FontAwesomeIcon icon={faLocationDot} style={{"--fa-primary-color": "#000000", "--fa-secondary-color": "#ff3c5a", "--fa-secondary-opacity": "1",}} /> */}
                                        <ListItemText primary="Select Sector :" />
                                      </ListItem>
                                      {/* <label>Select Sector :</label> */}

                                      <ListItem
                                        button
                                        onClick={() =>
                                          handleOptionClick("Sector Select")
                                        }
                                        options={sectorOptions}
                                        onChange={handleSectorChange}
                                        style={{
                                          color: "#ff3c5a",
                                          marginTop: "-2em",
                                        }}
                                      >
                                        <ListItemText
                                          primary={selectedSector}
                                        />
                                        <FontAwesomeIcon icon={faAngleRight} />
                                      </ListItem>

                                      <ListItem>
                                        {/* <FontAwesomeIcon icon={faPenRuler} style={{"--fa-primary-opacity": "1", "--fa-secondary-color": "#ff3c5a", "--fa-secondary-opacity": "1",}} /> */}
                                        <ListItemText primary="Select Size :" />
                                      </ListItem>

                                      {/* <label>Select Size</label> */}
                                      <ListItem
                                        button
                                        onClick={() =>
                                          handleOptionClick("Size Select")
                                        }
                                        options={sizesOptions}
                                        onChange={handleSizeChange}
                                        style={{
                                          color: "#ff3c5a",
                                          marginTop: "-2em",
                                        }}
                                      >
                                        <ListItemText primary={selectedSize} />
                                        <FontAwesomeIcon icon={faAngleRight} />
                                      </ListItem>

                                      <ListItem button onClick={handleClick}>
                                        {/* <ListItemText primary="Submit" /> */}
                                        <div
                                          style={{
                                            textAlign: "center",
                                            height: "100%",
                                            width: "100%",
                                          }}
                                        >
                                          <CustomButton onClick={handleClick}>
                                            <FaSearch />
                                          </CustomButton>
                                        </div>
                                      </ListItem>
                                    </List>
                                  </div>
                                </Drawer>
                                <Drawer
                                  anchor="right"
                                  open={subDrawerOpen}
                                  onClose={toggleSubDrawer}
                                >
                                  <div style={{ width: 250 }}>
                                    <List>
                                      <ListItem>
                                        <ListItemText
                                          primary={`Filter (${selectedOption})`}
                                        />
                                        <Button
                                          // variant="outlined"

                                          color="error"
                                          onClick={toggleSubDrawer}
                                          style={{ marginLeft: "auto" }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faXmark}
                                            size="xl"
                                          />
                                        </Button>
                                      </ListItem>
                                      {selectedOption === "Phase Select" && (
                                        <>
                                          {allphases.map((phase) => (
                                            <ListItem
                                              key={phase}
                                              button
                                              onClick={() =>
                                                handlePhaseChange({
                                                  value: phase,
                                                })
                                              }
                                            >
                                              <ListItemText primary={phase} />
                                            </ListItem>
                                          ))}
                                        </>
                                      )}
                                      {selectedOption === "Sector Select" && (
                                        <>
                                          {loadPhase.map((phase) => (
                                            <ListItem
                                              key={phase}
                                              button
                                              onClick={() =>
                                                handleSectorChange({
                                                  value: phase,
                                                })
                                              }
                                            >
                                              <ListItemText primary={phase} />
                                            </ListItem>
                                          ))}
                                        </>
                                      )}

                                      {selectedOption === "Size Select" && (
                                        <>
                                          {/* {sizes.map((size) => ( */}
                                          {filteredSizes.map((size) => (
                                            <ListItem
                                              key={size}
                                              button
                                              // onClick={() =>
                                              //   handleSizeChange(size)
                                              // }
                                              onClick={() =>
                                                handleSizeChange({
                                                  value: size,
                                                })
                                              }
                                              onChange={handleSizeChange}
                                            >
                                              <ListItemText primary={size} />
                                            </ListItem>
                                          ))}
                                        </>
                                      )}
                                    </List>
                                  </div>
                                </Drawer>
                              </React.Fragment>
                            ))}
                          </div>
                        </MobileView>
                      </FilterContext.Provider>
                    </div>
                  </Col>
                  <Col sm={12} md={8} lg={8} xs={12}>
                    {filteredData.length > 0 ? (
                      // <div className="row container">
                      <div>
                        <PaginatedItems itemsPerPage={8} 
                        // items={filteredData} 
                        items={filteredData.sort(
                          (a, b) => new Date(b.date) - new Date(a.date)
                        )}
                        />
                      </div>
                    ) : (
                      <p>
                        We currently don't have any property in this sector.{" "}
                      </p>
                    )}
                  </Col>

                  <Col
                    md={4}
                    lg={4}
                    xs={12}
                    // style={{borderLeft : "1px solid black"}}
                  >
                    {/* <div> */}
                    <SidebarV1 />
                    {/* </div> */}
                  </Col>
                </Row>
              </div>

              {/* ltn__product-item */}

              {/*  */}
            </div>
          </div>
        </div>
      </div>
      {
          myLoader &&
            <Loader />

          // <Circles type="Circles" color="#00BFFF" height={80} width={80}/>

        }
    </div>
  );
}

function Items({ currentItems }) {
  return (
    <Row md={12} lg={12} xl={12} sm={12} xs={12}>
      {currentItems &&
        currentItems.map((plot, index) => (
          <Col key={plot.id} md={6} lg={3} xl={3} sm={6} xs={6}>
            {/*           <Col key={plot.id} md={6} lg={4} xl={3} sm={6} xs={12}> */}
            <div key={plot.id} className="d-flex">
              <Plot
                price={plot.price}
                image={plot.image}
                datatype={plot.datatype}
                title={plot.title}
                city={plot.city}
                phase={plot.phase}
                sector={plot.sector}
                id={plot.id}
                callnow={plot.details && plot.details.callnow}
                _id={plot._id}
                location={plot.location}
                size={plot.size}
                status={plot.details.status}
                customStatus={plot.details.customStatus}
                category={plot.details.category}
                description={plot.details.description}
              />
            </div>
          </Col>
        ))}
    </Row>
  );
}

function PaginatedItems({ itemsPerPage, items }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        className="pagination"
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        pageCount={pageCount}
        nextLabel="Next"
        previousLabel="Back"
        // nextLabel={<ArrowForwardIosIcon />}
        // previousLabel={<ArrowBackIosIcon />}
        renderOnZeroPageCount={null}
      />
    </>
  );
}

function Plot(props) {
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");
  function formatphonenumber(phoneNumber) {
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
      3,
      5
    )}-${phoneNumber.substring(5)}`;
  }

  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/plot/image/" + props.image, {
    fetch("https://property.aiodock.com/fortune/plot/image/" + props.image, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.datatype + "base64," + response.data);
        setExtension(props.image.split(".")[1]);
      });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let phoneNumber = props.callnow;

  if (phoneNumber.charAt(0) === "0") {
    phoneNumber = "+92" + phoneNumber.slice(1);
  }
  return (
    <div
      key={props._id}
      className="ltn_product-item ltnproduct-item-4 ltn_product-item-5"
      style={{ marginBottom: "1.5em" }}
    >
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card
          // style={{marginBottom:'1.5em'}}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Link
                key={props.id}
                to={`/plot-details-commercial/${props.id}`}
                // style={{ flex: 1 }}
              >
                <Card.Img
                  className=""
                  style={{ width: "100%" }}
                  // variant="top"
                  src={file}
                />
              </Link>
              <div
                // <Card.Body
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <Card.Title>
                  <h3
                    className="ltn__blog-title"
                    style={{
                      // width: "100%",
                      // textAlign: "center",
                      // margin: 0,
                      fontSize: "1em",
                      width: "100%",
                      // textAlign: "center",
                      marginTop: "0.5em",
                      marginLeft: "0.6em",
                    }}
                  >
                    <Link
                      key={props._id}
                      to={`/plot-details-commercial/${props.id}`}
                    >
                      {/* Price: {props.price} */}
                      Price : {props.price ? props.price : props.title}
                    </Link>
                  </h3>
                  <div>
                    <h6
                      style={{
                        marginTop: "-1em",
                        marginLeft: "0.8em",
                        fontSize: "0.8em",
                        fontWeight: "600",
                      }}
                    >
                      {/* DHA {props.phase} */}
                      {props.phase === "DHA Valley" ? (
                        <p>{props.phase}</p>
                      ) : (
                        <p>DHA {props.phase}</p>
                      )}
                    </h6>
                  </div>
                  <div>
                    <p
                      style={{
                        marginTop: "-0.8em",
                        marginLeft: "0.8em",
                        marginRight: "0.5em",
                        fontSize: "0.7em",
                      }}
                    >
                      {props.size} | {props.sector} | {props.category}
                    </p>
                  </div>

                  {/* <div
                    className="ltn__blog-meta-btn"
                    style={{
                      // display: "flex",
                      // justifyContent: "space-between",
                      // marginTop: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-1.1em",
                      marginLeft: "0.5em",
                    }}
                  >
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far flaticon-pin" />
                          {props.city}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        // marginTop: "0.4em",
                        // marginLeft: "-0.7em",
                        // marginRight: "0.5em",
                        // fontSize: "0.7em",
                        marginTop: "0.5em",
                        marginLeft: "0.8em",
                        marginRight: "0.5em",
                        fontSize: "0.7em",
                      }}
                    >
                      {props.phase} | {props.sector} | {props.size} |{" "}
                      {props.category}
                    </p>
                  </div> */}
                </Card.Title>

                <div
                  className="mobile-call-button1"
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    justifyContent: "left",
                    alignItems: "left",
                    marginLeft: "1em",
                    flex: "1",
                    marginTop: "-1rem",
                    marginBottom: "1rem",

                    //  marginTop: "-2rem", width: "100%" , height: "5%"
                  }}
                >
                  <a
                    style={{
                      borderRadius: "5px",
                      // width: "4.5em",
                      // width: "60%",
                      // width: "40%",
                      height: "2em",
                      letterSpacing: "0.1em",
                      fontSize: "0.9em",
                      padding: "0.2em 0.5em",
                      marginRight: "0.5rem",
                      marginTop: "auto",
                    }}
                    href={"tel:" + (props.callnow || "")}
                    className="theme-btn-call btn"
                  >
                    <strong>
                      Call
                      {/* {((props.details && props.details.callnow) || "").replace(
                        /(\d{4})(\d+)/,
                        "$1-$2"
                      )} */}
                    </strong>
                  </a>
                  <a
                    style={{
                      borderRadius: "5px",
                      width: "2em",
                      height: "1.8em",
                      backgroundColor: "#25D366", // WhatsApp green color
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    href={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp style={{ color: "white" }} />
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ShopGridV1;
