import React, { Component, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

class SidebarV2 extends Component {
  constructor() {
    super();
    this.state = {
      blogs: [],
    };
  }
  componentDidMount() {
    fetch("https://property.aiodock.com/fortune/blog/three").then((result) => {
    // fetch("https://property.aiodock.com/fortune/blog/three").then((result) => {
      result.json().then((response) => {
        this.setState({ blogs: response });
      });
    });
  }
  render() {
    let anchor = "#";
    let imagealt = "image";
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div>
        <aside className="sidebar-area">
          <div className="widget widget-news">
            <h1
              className="widget-title"
              style={{ textAlign: "center", fontWeight: "800" }}
            >
              Recent Blogs
            </h1>
            {this.state.blogs.map((blog, index) => {
              return <SingleBlog blog={blog} />;
            })}
          </div>
        </aside>
      </div>
    );
  }
}

function SingleBlog(props) {
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");

  useEffect(() => {
    fetch(
      "https://property.aiodock.com/fortune/blog/image/" + props.blog.image,
      // "https://property.aiodock.com/fortune/blog/image/" + props.blog.image,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.blog.datatype + "base64," + response.data);
        setExtension(props.blog.image.split(".")[1]);
      });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className="single-news-wrap media mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Row>
        <Col lg={6} sm={12} md={12}>
          <div className="thumb">
            <img
              src={file}
              alt="img"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = `/blog-details/${props.blog.id}`;
              }}
            />
          </div>
        </Col>
        <Col lg={6} sm={12} md={12}>
          <div className="media-body">
            <h6 style={{ fontWeight: "800" }}>
              {" "}
              <Link
                className="read-more"
                key={props.blog.id}
                to={`/blog-details/${props.blog.id}`}
              >
                {props.blog.title}
              </Link>
            </h6>
            <h6 style={{ fontSize: "0.7em",  }}>
              <i
                style={{ color: "#ff5a3c" }}
                class="far fa-light fa-user mx-2"
              />
              {props.blog.author ? props.blog.author : "Anonymus"} |
              <i
                style={{ color: "#ff5a3c" }}
                className="far fa-calendar-alt mx-2 "
              />
              {new Date(props.blog.date).toDateString()}
            </h6>
            {/* <h6 style={{ fontSize: "0.7em", marginLeft: "-0.5em" }}>
              <i
                style={{ color: "#ff5a3c" }}
                class="far fa-light fa-user mx-2"
              />
              {props.blog.author ? props.blog.author : "Anonymus"} |
              <i
                style={{ color: "#ff5a3c" }}
                className="far fa-calendar-alt mx-2 "
              />
              {new Date(props.blog.date).toDateString()}
            </h6> */}
            {/* <p
              class="text-truncate"
              style={{
                color: "#1A2B56",
                // margin: "1em",
                // maxHeight: "10px",
              }}
            >
              {ReactHtmlParser(props.blog.description)}
            </p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SidebarV2;
