import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Row, Col } from "reactstrap";

class  CallToActonV5 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div
        style={{ borderRadius: "15px" }}
        className="ltn__call-to-action-area call-to-action-6 mb-3"
         
         // use this if you want to reduce space from below
        // className="ltn__call-to-action-area call-to-action-6  mb-2"
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div
                style={{ borderRadius: "5px" }}
                className="call-to-action-inner  call-to-action-inner-6 ltn__secondary-bg position-relative text-center---"
              >
                <div className="coll-to-info text-color-white">
                  <h1 style={{ fontWeight: "600" }}>Get Your Piece of Land</h1>
                  <h4 style={{ color: "#22355b", fontWeight: "600" , letterSpacing:"0.1em" , marginTop:"-0.5em",}}>
                    Explore biggest inventory in DHA RWP-ISB
                  </h4>
                </div>
                <div
                  // style={{
                  //   backgroundImage: `url(${publicUrl}assets/img/bg-05.png)`,
                  //   backgroundSize: "cover",
                  //   // backgroundPosition: "center",
                  //   // paddingTop: "100px",
                  //   height:'120px',
                  //   // width:'40%'
                  // }}
                  className="btn-wrapper go-top"
                >
                  <Row>
                    <Col xs={6} md={6} sm={4} xl={6}>
                      <Link
                        style={{
                          borderRadius: "5px",
                          color: "#22355b",
                          backgroundColor: "white",
                          width: "8.75em",
                        }}
                        className="btn btn-white"
                        to="/houses"
                      >
                        Houses{" "}
                      </Link>
                    </Col>
                    <Col xs={6} md={6} sm={4} xl={6}>
                      <Link
                        style={{
                          borderRadius: "5px",
                          color: "white",
                          backgroundColor: "#22355b",
                          width: "8.75em",
                        }}
                        className="btn btn-white"
                        to="/commercials"
                      >
                        Plots{" "}
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallToActonV5;
