import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Slider from "react-slick";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "none",
        margin: "0 20px",
        fontSize:'24px'
      }}
      onClick={onClick}
    >
      <i className="fa fa-chevron-right" aria-hidden="true"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "none",
        margin: "0 20px",
        fontSize:'24px'
      }}
      onClick={onClick}
    >
      <i className="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
  );
}

class ProductSliderV1 extends Component {
  constructor() {
    super();
    this.state = {
      featurePlots: [],
    };
  }

  componentDidMount() {
    fetch("https://property.aiodock.com/fortune/plot/feature", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        this.setState({ featurePlots: response });
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    

    return (
      <>
        {this.state.featurePlots.length === 0 ? null : (
          <div
            style={{
              backgroundImage: `url(${publicUrl}assets/img/bg-02.png)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-area ltn__section-title-2--- text-center">
                      {/* <h6 className="section-subtitle section-subtitle-2 ">
                        Properties
                      </h6> */}
                      <h1
                        style={{ fontWeight: "800" }}
                        className=" ltn__secondary-color"
                      >
                        Featured Listings
                      </h1>
                    </div>
                  </div>
                </div>
                {/* <Fade bottom duration={1500}> */}
                <Slider
                  style={{ display: "flex", alignItems: "center" }}
                  {...settings}
                >
                  {/* ltn__product-item */}
                  {this.state.featurePlots.map((plot, index) => {
                    return (
                      <Plot
                        category={plot.category}
                        key={plot.id}
                        city={plot.city}
                        id={plot.id}
                        description={plot.description}
                        datatype={plot.datatype}
                        location={plot.location}
                        image={plot.image}
                        price={plot.price}
                        size={plot.size}
                        title={plot.title}
                        Plotcategory={plot.details.category}
                      />
                    );
                  })}
                </Slider>

                {/* </Fade> */}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function Plot(props) {
  const [file, setFile] = React.useState("");
  const [extension, setExtension] = React.useState("");
  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/plot/image/" + props.image, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Service Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.datatype + "base64," + response.data);
        setExtension(props.image.split(".")[1]);
      });
  }, []);
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div key={props.id} style={{ margin: "8px" }}>
      <Fade bottom duration={1500}>
        <div className="ltn__testimonial-item ltn__testimonial-item-3">
          <div className="ltn__testimonial-img blog-image">
            <Link
              to={
                props.category == "Houses"
                  ? `/plot-details-houses/${props.id}`
                  : props.category == "Residential"
                  ? `/plot-details-residential/${props.id}`
                  : `/plot-details-commercial/${props.id}`
              }
            >
              <img id="blog-image" src={file} alt="Image" />
            </Link>
          </div>
          <div className="ltn__testimoni-info">
            <div className="ltn__testimoni-info-inner">
              <div className="ltn__testimoni-name-designation  ">
                <Link
                  to={
                    props.category == "Houses"
                      ? `/plot-details-houses/${props.id}`
                      : props.category == "Residential"
                      ? `/plot-details-residential/${props.id}`
                      : `/plot-details-commercial/${props.id}`
                  }
                >
                  <h4
                    style={{ fontWeight: "700" }}
                    className="ltn__secondary-color"
                  >
                    {props.title}
                  </h4>
                </Link>
                <h5>Size: {props.size}</h5>
                <div
                  className=" ltn__blog-btn"
                  style={{
                    backgroundColor: "#ff5a3c",
                    borderRadius: "5px",
                    height: "40px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ color: "white", fontWeight: "700", paddingRight:'1em', paddingLeft:'1em' }}>
                    Price: {props.price}
                  </h6>
                </div>
              </div>
            </div>
            <div className="ltn__testimoni-bg-icon">
              <i style={{ color: "#FF5A3C" }} />
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default ProductSliderV1;
