import React, { useEffect } from "react";
import { FaYoutube } from "react-icons/fa";
function Video() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [Banner, setBanner] = React.useState("");

  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/configure").then((result) => {
    fetch("https://property.aiodock.com/fortune/configure").then((result) => {
      result.json().then((response) => {
        setBanner(response[0].youtube.Banner);
      });
    });
  }, []);

  return (
    <section className="video_area bg_color mt-5">
      <div
        className="container-fluid"
        style={{
          backgroundImage: `url(${publicUrl}assets/img/bg-03.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="section-title" style={{ textAlign: "center" }}>
          <h1 style={{ color: "black", fontSize: "36", fontWeight: "500" }}>
            A new way to explore and discover DHA
          </h1>
          <h1
            className="ltn__secondary-color"
            style={{ fontWeight: "800", border: "1px", fontSize: "48" }}
          >
            RAWALPINDI ISLAMABAD
          </h1>
        </div>
        {/* <Sectitle Title="Expertise in Digital Solutions" TitleP="The present promotion world is packed. Going visual is one of the powerful methods for sticking out. As well as interesting clients, computerized content is unimaginably significant." tClass="t_color3" sClass="sec_title text-center mb_70"/> */}
        <div className="video_content">
          <div className="video_info">
            <div className="ovarlay_color"></div>
            <a
              className="popup-youtube "
              //  href={Banner}
              href="https://www.youtube.com/@PropertyGupshup"
            >
              <i style={{ color: "white", fontSize: "64px" }}>
                <FaYoutube />
              </i>
            </a>

            <h2></h2>
          </div>
          {/* <img className="video_leaf" src={require('../img/new/leaf.png')} alt=""/>
                    <img className="cup" src={require('../img/new/cup.png')} alt=""/> */}
        </div>
      </div>
    </section>
  );
}

export default Video;
