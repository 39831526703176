import React, { useState, useEffect } from "react";
import Navbar from "./global-components/navbar-v3";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import AllBlogs from "./blog-components/blog-grid-v1";
import { Helmet } from "react-helmet";
const BlogGridPage = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [imageData, setImageData] = useState("");
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   fetch(`https://property.aiodock.com/fortune/website/pageImage/40.png`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Image not found");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setImageData(data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Image fetch error:", error);
  //       setError(error.message);
  //     });
  // }, []);
  useEffect(() => {
    // Function to clear the entire cache when the component mounts
    const clearCache = () => {
      console.log("Clearing cache...");
      if ("caches" in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }
      console.log("Cache cleared.");
    };

    // Call the clearCache function when the component mounts
    clearCache();
  }, []);
  
  return (
    <div>
      <Helmet>
        <title>Propex | Blogs</title>
      </Helmet>
      <Navbar />
      <div
        style={{ height: "", marginBottom: "100px" }}
        className="bg-overlay-theme-black-15 navbar-image ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image "
        // data-bs-bg={publicUrl + "assets/img/blogs2.jpg"}
        // data-bs-bg={`data:image/png;base64,${imageData}`}
        data-bs-bg={`https://property.aiodock.com/fortune/website/Blogs.png`}
      ></div>
      <AllBlogs className="mt-5" />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default BlogGridPage;
