import React, { useState, useEffect } from "react";
import Navbar from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import BlogRightSidebar from "./blog-components/blog-right-sidebar";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import Banner from "./section-components/banner-v3";

const BlogRightSidebarPage = () => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const [imageData, setImageData] = useState("");
    const [error, setError] = useState(null);

    // useEffect(() => {
    //   fetch(`https://property.aiodock.com/fortune/website/pageImage/40.png`)
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error("Image not found");
    //       }
    //       return response.json();
    //     })
    //     .then((data) => {
    //       setImageData(data.data);
    //     })
    //     .catch((error) => {
    //       console.error("Image fetch error:", error);
    //       setError(error.message);
    //     });
    // }, []);

  return (
    <div>
      <Navbar />
      <div
        style={{ height: "100vh" }}
        className=" navbar-image ltn__slide-item ltn__slide-item-2 bg-overlay-theme-black-60 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
        // data-bs-bg={publicUrl + "assets/img/slider/background.jpg"}
        // data-bs-bg={`data:image/png;base64,${imageData}`}
        data-bs-bg={`https://property.aiodock.com/fortune/website/74.png`}
      >
        <Banner />
      </div>
      {/* <PageHeader headertitle="Blog" subheader="Blog" /> */}
      <BlogRightSidebar />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default BlogRightSidebarPage;
