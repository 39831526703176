import React, { useState, useEffect } from "react";
import Navbar from "./global-components/navbar-v3";
import MainVideo from "./section-components/about-v10";
import YoutubeVideos from "./section-components/about-v9";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import Thumbnail from "./section-components/thumbnail";
import { Helmet } from "react-helmet";

const Service_V1 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [imageData, setImageData] = useState("");
  const [error, setError] = useState(null);


  // useEffect(() => {
  //   // fetch(`https://property.aiodock.com/fortune/website/pageImage/41.png`)
  //   fetch(`https://property.aiodock.com/fortune/website/pageImage/41.png`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Image not found");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setImageData(data.data);
  //       // console.log("Test dat",data.data)
  //     })
  //     .catch((error) => {
  //       console.error("Image fetch error:", error);
  //       setError(error.message);
  //     });
  // }, []);


  return (
    <div>
      <Helmet>
        <title>Propex | Property Gupshup</title>
      </Helmet>
      <Navbar />
      <div
        style={{ height: "", marginBottom: "50px" }}
        className=" navbar-image ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image "
        // data-bs-bg={publicUrl + "assets/img/v2.png"}
        // data-bs-bg={`data:image/png;base64,${imageData}`}
        data-bs-bg={`https://property.aiodock.com/fortune/website/Property%20Gupshup.png`}
      ></div>
      <MainVideo />
      <YoutubeVideos />
      <Thumbnail />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default Service_V1;
