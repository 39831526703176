import React, { useState, useEffect } from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import { FileDownload } from "@mui/icons-material";
import Container from "@mui/material/Container";

function MapModal(props) {
  return (
    <React.Fragment>
      <Container fluid>
        <ListGroup variant="flush">
          <ListGroup.Item style={{ marginBottom: "5px", padding: "0" }}>
            <Row>
              <Col lg={7} xs={7} md={7} sm={7}>
                <p>{props.title}</p>
              </Col>
              <Col lg={4} xs={4} md={4} sm={4}>
                <a
                  href={props.pdfSrc}
                  download={props.title} 
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="outlined"
                    style={{ borderColor: "#ff5a3c", color: "#ff5a3c" }}
                    size="small"
                    startIcon={<FileDownload />}
                  >
                    Download
                  </Button>
                </a>
              </Col>
              <Col lg={1} xs={1} md={1} sm={1}></Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </React.Fragment>
  );
}

function Map() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/document")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Image not found");
        }
        return response.json();
      })
      .then((data) => {
        setDocuments(data);
        console.log("Data is:", data);
      })
      .catch((error) => {
        console.error("Image fetch error:", error);
        // setError(error.message);
      });
  }, []);

  return (
    <div className="container">
      <div className="container mx-50">
        <Row>
          {documents.length > 0 &&
            documents.map((document, index) => (
              <Col xs={12} lg={6} md={6} key={index}>
                <MapModal
                  title={document.title}
                  imageSrc={document.imageSrc}
                  datatype={document.datatype}
                  pdfSrc={`https://property.aiodock.com/fortune/document/download/${document.pdf}`}
                />
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
}

export default Map;
