import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Reveal, Slide } from "react-reveal";
import { Col, Row } from "reactstrap";
import { BsPlay } from "react-icons/bs";

function Main() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [Banner, setBanner] = React.useState("");

  const [PropertyGhupshup, setPropertyGhupshup] = React.useState("");

  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/configure").then((result) => {
    fetch("https://property.aiodock.com/fortune/configure").then((result) => {
      result.json().then((response) => {
        setPropertyGhupshup(response[0].youtube.PropertyGhupshup);
      });
    });
  }, []);

  return (
    <div
      className="ltn__about-us-area  go-top"
      style={{
        marginTop: "50px",
        backgroundImage: `url(${publicUrl}assets/img/bg-01.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <Row>
          <Col xs={12} md={6} lg={6}>
            <div className="mt-100">
              <div className="section-title">
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "32px",
                    margin:'0',
                    padding:'0'
                  }}
                >
                  We show DHA RWP-ISB
                </p>
                <h1
                  className="ltn__secondary-color"
                  style={{
                    fontWeight: "800",
                    fontSize: "48px",
                  }}
                >
                  FROM GROUND ZERO
                </h1>
                {/* <h1 style={{ color: "black", fontSize: "28px" }}>
                    of all things in DHA Rawalpindi Islamabad.
                  </h1> */}
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            {" "}
            <div className="">
              {/* <div className="video_content">
                <div className="video_propertyGupshup">
                  <a className="popup-youtube " href={Banner}>
                    <i style={{ color: "white", fontSize: "64px" }}>
                      <a
                        href="https://www.youtube.com/@PropertyGupshup"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{ marginBottom: "50px" }}
                          src={publicUrl + "assets/img/play-button.png"}
                          alt="Play button"
                        />
                        {/* <BsPlay style={{ color: "black", marginTop:'-1px'}} /> 
                    </i>
                  </a>
                </div>
              </div> */}
              <iframe
                width="100%"
                height="330"
                src={PropertyGhupshup}
                title="YouTube video player"
                frameborder="0"
                autoplay="1"
                allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> 
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Main;
