import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Slider from "react-slick";
import { Row, Col } from "reactstrap";
import CallIcon from "@mui/icons-material/Call";
import { FaWhatsapp } from "react-icons/fa";
import { Card } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

function FeatureSliderV1() {
  const [featurePlots, setFeaturePlots] = useState([]);


const fetchData = () => {
  fetch("https://property.aiodock.com/fortune/plot/feature", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 400) {
          alert("No Plot Found");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setFeaturePlots(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
}

  useEffect(() => {
    fetchData()
  }, []);
  

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("https://property.aiodock.com/fortune/plot/feature", {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (response.status === 400) {
  //         alert("No Plot Found");
  //       } else {
  //         const data = await response.json();
  //         setFeaturePlots(data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
    
  //   fetchData();
  // }, []);


  // console.log("API Response:", data);
  console.log("State after setting:", featurePlots);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      {featurePlots.length === 0 ? null : (
        <div
          style={{
            backgroundImage: `url(${publicUrl}assets/img/bg-02.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="ltn_product-slider-area ltn_product-gutter pt-20 plr--7">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-area ltn__section-title-2--- text-center">
                    <h1
                      style={{ fontWeight: "600", letterSpacing: "0.1em" }}
                      className=" ltn__secondary-color"
                    >
                      FEATURED PROPERTIES
                    </h1>
                  </div>
                </div>
              </div>
              <Row className="home-call-to-action">
                {featurePlots.map((plot, index) => (
                  <Col className="mb-3" lg={4} md={4} sm={12} key={plot.id}>
                    <Plot
                      category={plot.category}
                      key={plot.id}
                      city={plot.city}
                      id={plot.id}
                      description={plot.description}
                      datatype={plot.datatype}
                      location={plot.location}
                      image={plot.image}
                      price={plot.price}
                      size={plot.size}
                      title={plot.title}
                      phase={plot.phase}
                      sector={plot.sector}
                      Plotcategory={plot.details.category}
                      callnow={plot.details.callnow}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Plot(props) {
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");
  const history = useHistory();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`https://property.aiodock.com/fortune/plot/image/${props.image}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 400) {
          alert("No Service Found");
        } else {
          const responseData = await response.json();
          setFile(props.datatype + "base64," + responseData.data);
          setExtension(props.image.split(".")[1]);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [props.image]);
  // }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let phoneNumber = props.callnow;

  if (phoneNumber.charAt(0) === "0") {
    phoneNumber = "+92" + phoneNumber.slice(1);
  }

  return (
    <div key={props.id} style={{ marginTop: "-2em" }}>
      <Fade bottom duration={1500}>
        <Card
          style={{
            marginBottom: "2.2em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Link
             key={props.id}
              to={
                props.category == "houses"
                  ? `/plot-details-houses/${props.id}`
                  : props.category == "residential"
                  ? `/plot-details-residential/${props.id}`
                  : `/plot-details-commercial/${props.id}`
              }
              // onClick={() => {
              //   history.push(
              //     props.category === "houses"
              //       ? `/plot-details-houses/${props.id}`
              //       : props.category === "residential"
              //       ? `/plot-details-residential/${props.id}`
              //       : `/plot-details-commercial/${props.id}`
              //   );
              // }}
            >
              <Card.Img className="" style={{ height: "269px" }} src={file} />
            </Link>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Card.Title style={{ marginLeft: "1em" }}>
                <h3
                  className="ltn__blog-title"
                  style={{
                    width: "100%",
                    marginTop: "0.5em",
                    fontSize: "1.3em",
                  }}
                >
                  <Link
                    key={props._id}
                    to={
                      props.category === "houses"
                        ? `/plot-details-houses/${props.id}`
                        : props.category === "residential"
                        ? `/plot-details-residential/${props.id}`
                        : `/plot-details-commercial/${props.id}`
                    }
                  >
                    Price : {props.price ? props.price : props.title}
                  </Link>
                </h3>
                <div>
                  <h6
                    style={{
                      marginTop: "-0.9em",
                      fontSize: "1em",
                      fontWeight: "600",
                    }}
                  >
                    DHA {props.phase}
                  </h6>
                </div>
                <div>
                  <p
                    style={{
                      marginTop: "-0.6em",
                      fontSize: "1em",
                      fontWeight: "500",
                    }}
                  >
                    {props.size} | {props.sector} | {props.Plotcategory}
                  </p>
                </div>
              </Card.Title>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flex: "1",
                marginTop: "-1rem",
                marginBottom: "1rem",
                marginLeft: "1em",
              }}
            >
              <a
                style={{
                  borderRadius: "5px",
                  width: "29%",
                  height: "2em",
                  letterSpacing: "0.1em",
                  fontSize: "0.9em",
                  padding: "0.2em 0.5em",
                  marginTop: "auto",
                }}
                href={`tel:${props.callnow}`}
                className="theme-btn-call btn"
              >
                <strong>Call</strong>
              </a>
              <a
                style={{
                  borderRadius: "5px",
                  width: "2em",
                  height: "1.8em",
                  backgroundColor: "#25D366",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href={`https://wa.me/${phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp style={{ color: "white" }} />
              </a>
            </div>
          </div>
        </Card>
      </Fade>
    </div>
  );
}

export default FeatureSliderV1;
























// import React, { Component, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Fade } from "react-reveal";
// import Slider from "react-slick";
// import { Row, Col } from "reactstrap";
// import CallIcon from "@mui/icons-material/Call";
// import { FaWhatsapp } from "react-icons/fa";
// import { Card } from "react-bootstrap";

// class FeatureSliderV1 extends Component {
//   constructor() {
//     super();
//     this.state = {
//       featurePlots: [],
//     };
//   }

//   componentDidMount() {
//     fetch("https://property.aiodock.com/fortune/plot/feature", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((res) => {
//         if (res.status === 400) {
//           alert("No Plot Found");
//         } else {
//           return res.json();
//         }
//       })
//       .then((response) => {
//         this.setState({ featurePlots: response });
//       });
//   }

//   render() {
//     let publicUrl = process.env.PUBLIC_URL + "/";

//     return (
//       <>
//         {this.state.featurePlots.length === 0 ? null : (
//           <div
//             style={{
//               backgroundImage: `url(${publicUrl}assets/img/bg-02.png)`,
//               backgroundSize: "cover",
//               backgroundPosition: "center",
//             }}
//           >
//             <div className="ltn_product-slider-area ltn_product-gutter pt-20 plr--7">
//               <div className="container-fluid">
//                 {/* <div className="container"> */}
//                 <div className="row">
//                   <div className="col-lg-12">
//                     <div className="section-title-area ltn__section-title-2--- text-center">
//                       {/* <h6 className="section-subtitle section-subtitle-2 ">
//                         Properties
//                       </h6> */}
//                       <h1
//                         style={{ fontWeight: "600", letterSpacing: "0.1em" }}
//                         className=" ltn__secondary-color"
//                       >
//                         FEATURED PROPERTIES
//                       </h1>
//                     </div>
//                   </div>
//                 </div>
//                 {/* <Fade bottom duration={1500}> */}
//                 <Row className="home-call-to-action">
//                   {/* ltn__product-item */}
//                   {this.state.featurePlots.map((plot, index) => {
//                     return (
//                       <Col className="mb-3" lg={4} md={4} sm={12} key={plot.id}>
//                         <Plot
//                           category={plot.category}
//                           key={plot.id}
//                           city={plot.city}
//                           id={plot.id}
//                           description={plot.description}
//                           datatype={plot.datatype}
//                           location={plot.location}
//                           image={plot.image}
//                           price={plot.price}
//                           size={plot.size}
//                           title={plot.title}
//                           phase={plot.phase}
//                           sector={plot.sector}
//                           Plotcategory={plot.details.category}
//                           callnow={plot.details.callnow}
//                         />
//                       </Col>
//                     );
//                   })}
//                 </Row>
//               </div>
//             </div>
//           </div>
//         )}
//       </>
//     );
//   }
// }

// function Plot(props) {
//   const [file, setFile] = React.useState("");
//   const [extension, setExtension] = React.useState("");
//   useEffect(() => {
//     fetch("https://property.aiodock.com/fortune/plot/image/" + props.image, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((res) => {
//         if (res.status === 400) {
//           alert("No Service Found");
//         } else {
//           return res.json();
//         }
//       })
//       .then((response) => {
//         setFile(props.datatype + "base64," + response.data);
//         setExtension(props.image.split(".")[1]);
//       });
//   }, []);
//   let publicUrl = process.env.PUBLIC_URL + "/";
//   let phoneNumber = props.callnow;

//   if (phoneNumber.charAt(0) === "0") {
//     phoneNumber = "+92" + phoneNumber.slice(1);
//   }

//   return (
//     // <>
//     //   <div key={props.id} style={{ margin: "8px" }}>
//     //     <Fade bottom duration={1500}>
//     //       <div className="ltn_testimonial-item ltn_testimonial-item-3">
//     //         <div className="ltn__testimonial-img blog-image">
//     //           <Link
//     //             to={
//     //               props.category == "houses"
//     //                 ? `/plot-details-houses/${props.id}`
//     //                 : props.category == "Residential"
//     //                 ? `/plot-details-residential/${props.id}`
//     //                 : `/plot-details-commercial/${props.id}`
//     //             }
//     //           >
//     //             <img id="blog-image" src={file} alt="Image" />
//     //           </Link>
//     //         </div>
//     //         <div className="ltn__testimoni-info">
//     //           <div className="ltn__testimoni-info-inner">
//     //             <div className="ltn__testimoni-name-designation  ">
//     //               <Link
//     //                 to={
//     //                   props.category == "houses"
//     //                     ? `/plot-details-houses/${props.id}`
//     //                     : props.category == "Residential"
//     //                     ? `/plot-details-residential/${props.id}`
//     //                     : `/plot-details-commercial/${props.id}`
//     //                 }
//     //               >
//     //                 <h4
//     //                   style={{ fontWeight: "700" }}
//     //                   className="ltn__secondary-color"
//     //                 >
//     //                   {props.title}
//     //                 </h4>
//     //               </Link>
//     //               <h5>Size: {props.size}</h5>
//     //               <div
//     //                 className=" ltn__blog-btn"
//     //                 style={{
//     //                   backgroundColor: "#ff5a3c",
//     //                   borderRadius: "5px",
//     //                   height: "40px",
//     //                   textAlign: "center",
//     //                   display: "flex",
//     //                   justifyContent: "center",
//     //                   alignItems: "center",
//     //                 }}
//     //               >
//     //                 <h6
//     //                   style={{
//     //                     color: "white",
//     //                     fontWeight: "700",
//     //                     paddingRight: "1em",
//     //                     paddingLeft: "1em",
//     //                     justifyContent: "center",
//     //                     alignItems: "center",
//     //                   }}
//     //                 >
//     //                   Price: {props.price}
//     //                 </h6>
//     //               </div>
//     //             </div>
//     //           </div>
//     //           <div className="ltn__testimoni-bg-icon">
//     //             <i style={{ color: "#FF5A3C" }} />
//     //           </div>
//     //         </div>
//     //       </div>
//     //     </Fade>
//     //   </div>
//     // </>
//     <div key={props.id} style={{ marginTop: "-2em" }}>
//       <Fade bottom duration={1500}>
//         <Card
//           style={{
//             marginBottom: "2.2em",
//             // marginLeft: "2em",
//             // marginRight: "2em",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               height: "100%",
//             }}
//           >
//             <Link key={props.id}
//             //  to={`/plot-details-residential/${props.id}`}
//             to={
//               props.category == "houses"
//                 ? `/plot-details-houses/${props.id}`
//                 : props.category == "residential"
//                 ? `/plot-details-residential/${props.id}`
//                 : `/plot-details-commercial/${props.id}`
//             }
//             >
//               <Card.Img className="" style={{ height: "269px" }} src={file} />
//             </Link>
//             <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
//               <Card.Title style={{ marginLeft: "1em" }}>
//                 <h3
//                   className="ltn__blog-title"
//                   style={{
//                     width: "100%",
//                     // textAlign: "center",
//                     marginTop: "0.5em",
//                     fontSize: "1.3em",
//                   }}
//                 >
//                   <Link
//                     key={props._id}
//                     // to={`/plot-details-residential/${props.id}`}
//                     to={
//                       props.category === "houses"
//                         ? `/plot-details-houses/${props.id}`
//                         : props.category === "residential"
//                         ? `/plot-details-residential/${props.id}`
//                         : `/plot-details-commercial/${props.id}`
//                     }
//                   >
//                     Price : {props.price ? props.price : props.title}
//                   </Link>
//                 </h3>
//                 {/* <div
//                   className="ltn__blog-meta-btn"
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     marginTop: "-0.6em",
//                     marginLeft: "0.5em",
//                   }}
//                 >
//                   <div
//                     className="ltn__blog-meta"
//                     style={{ marginBottom: "0.3em" }}
//                   >
//                     <ul>
//                       <li className="ltn__blog-date">
//                         <i className="far flaticon-pin" />
//                         {props.city}
//                         {props.phase}
//                       </li>
//                     </ul>
//                   </div>
//                 </div> */}

//                 <div>
//                   <h6
//                     style={{
//                       // marginTop: "0.1em",
//                       marginTop: "-0.9em",
//                       fontSize: "1em",
//                       fontWeight: "600",
//                     }}
//                   >
//                     DHA {props.phase}
//                   </h6>
//                 </div>
//                 <div>
//                   <p
//                     style={{
//                       // marginTop: "0.1em",
//                       marginTop: "-0.6em",
//                       fontSize: "1em",
//                       fontWeight: "500",
//                     }}
//                   >
//                     {props.size} | {props.sector} | {props.Plotcategory}
//                   </p>
//                 </div>
//               </Card.Title>
//             </div>

//             <div
//               className=""
//               style={{
//                 display: "flex",
//                 justifyContent: "left",
//                 alignItems: "left",
//                 flex: "1",
//                 marginTop: "-1rem",
//                 marginBottom: "1rem",
//                 marginLeft: "1em",
//                 //  marginTop: "-2rem", width: "100%" , height: "5%"
//               }}
//             >
//               <a
//                 style={{
//                   borderRadius: "5px",
//                   // width: "4.5em",
//                   // width: "40%",
//                   width: "29%",
//                   height: "2em",
//                   letterSpacing: "0.1em",
//                   fontSize: "0.9em",
//                   padding: "0.2em 0.5em",
//                   marginTop: "auto",
//                 }}
//                 href={
//                           "tel:" +
//                           ((props.callnow) || "")
//                         }
//                         className="theme-btn-call btn"
//               >
//                 <strong>
//                   Call
//                   {/* {((props.details && props.details.callnow) || "").replace(
//                     /(\d{4})(\d+)/,
//                     "$1-$2"
//                   )} */}
//                 </strong>
//               </a>
//               <a
//                 style={{
//                   borderRadius: "5px",
//                   width: "2em",
//                   height: "1.8em",
//                   backgroundColor: "#25D366", // WhatsApp green color
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                 }}
//                 href={`https://wa.me/${phoneNumber}`}
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <FaWhatsapp style={{ color: "white" }} />
//               </a>
//             </div>
//           </div>
//         </Card>
//       </Fade>
//     </div>
//   );
// }

// export default FeatureSliderV1;
