import React, { Component } from "react";
import { Link } from "react-router-dom";

class CallToActionV6 extends Component {
  openMapPDF = () => {
    const { property } = this.props;
    const phase = property.phase;
    // const sector = property.sector;
    
    // Generate the PDF file name based on the phase
    const pdfFileName = `DHA ${phase}.pdf`;
    // Construct the full file path
    const pdfFilePath = `/assets/pdf/pdfs/${pdfFileName}`;
    // Open the PDF file in a new tab
    window.open(pdfFilePath, "_blank");
  };

  render() {
    const { property } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div
              style={{
                borderRadius: "5px",
                // marginBottom:"2em"
              }}
              className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---"
            >
              <div className="coll-to-info text-color-white">
                {/* <h1 style={{ fontWeight: "800" }}>DHA {property.phase} Map</h1> */}
                <h1 style={{ fontWeight: "800" }}>
                  {property.phase === "DHA Valley" ? (
                    <h1>{property.phase} Map</h1>
                  ) : (
                    <h1>DHA {property.phase} Map</h1>
                  )}
                </h1>
              </div>
              <div className="">
                <div className="btn-wrapper go-top">
                  <div style={{ fontWeight: "600" }}>
                    <button
                      style={{ borderRadius: "5px" }}
                      className="btn btn-white"
                      onClick={this.openMapPDF}
                    >
                      View Map <i className="icon-next" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallToActionV6;
