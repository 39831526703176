import React, { useState, useEffect } from "react";
import Navbar from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import Maps from "./section-components/dhaMap";
import { Helmet } from "react-helmet";

const Cart_V1 = () => {
  let publicUrl = process.env.PUBLIC_URL + '/';

  const [imageData, setImageData] = useState("");
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   fetch(`https://property.aiodock.com/fortune/website/pageImage/43.png`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Image not found");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setImageData(data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Image fetch error:", error);
  //       setError(error.message);
  //     });
  // }, []);

  useEffect(() => {
    // Function to clear the entire cache when the component mounts
    const clearCache = () => {
      if ('caches' in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }
    };

    // Call the clearCache function when the component mounts
    clearCache();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Propex | Maps</title>
      </Helmet>
      <Navbar />
      <div
        style={{ height: "", marginBottom:'50px' }}
        className="bg-overlay-theme-black-15 navbar-image ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image "
        // data-bs-bg={publicUrl + "assets/img/maps.png"}
        // data-bs-bg={`data:image/png;base64,${imageData}`}
        data-bs-bg={`https://property.aiodock.com/fortune/website/Maps.png`}
      >
      </div>
      {/* <div className="ltn__breadcrumb-area-map bg-overlay-theme-black-30 map">
        <PageHeader headertitle="Map" />
      </div> */}
      <Maps/>
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default Cart_V1;
