import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CallToActonV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div
        style={{ borderRadius: "15px" }}
        className="ltn__call-to-action-area call-to-action-6  before-bg-bottom-footer"
      >
        <div className="container ">
          <div className="row">
            <div className="col-lg-12 ">
              <div
                style={{ borderRadius: "5px" }}
                className="call-to-action-inner  call-to-action-inner-6 ltn__secondary-bg position-relative text-center---"
              >
                <div className="coll-to-info text-color-white">
                  <h1 style={{ fontWeight: "600" }}>Navigate DHA Maps</h1>
                  <p
                    style={{
                      color: "#22355b",
                      fontWeight: "600",
                      fontSize:'1.3em',
                      letterSpacing: "0.1em",
                    }}
                  >
                    We have all maps of DHA Rawalpindi Islamabad
                  </p>
                </div>
                <div className="">
                  <div className="btn-wrapper go-top " />
                  <div style={{ fontWeight: "600" }}>
                    <Link
                      style={{ borderRadius: "5px" }}
                      className="btn  btn-white"
                      to="/map"
                    >
                      View Maps <i className="icon-next" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallToActonV1;
