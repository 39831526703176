import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import ReactHtmlParser from "react-html-parser";
import RecentBlogs from "./sidebar-v1";
import { FaFacebook, FaWhatsapp, FaInstagram, FaTwitter } from "react-icons/fa";
import { Helmet } from "react-helmet";
// import { Helmet } from "react-helmet-async";

function BlogDetails() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let { id } = useParams();
  const [blog, setBlog] = useState();
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");
  // const shouldRenderHelmet = true;

  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/blog/single/" + id).then(
    fetch("https://property.aiodock.com/fortune/blog/single/" + id).then(
      (result) => {
        result.json().then((response) => {
          setBlog(response[0]);
          fetch(
            // "https://fortune.aiodock.com/fortune/blog/image/" +
            "https://property.aiodock.com/fortune/blog/image/" +
              response[0].image,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => {
              if (res.status === 400) {
                alert("No Plot Found");
              } else {
                return res.json();
              }
            })
            .then((image) => {
              setFile(response[0].datatype + "base64," + image.data);
              console.log(image.data);
              setExtension(response[0].image.split(".")[1]);
            });
        });
      }
    );
  }, []);

  const openGraphTags = blog
    ? {
        title: blog.title,
        description: blog.content,
        // description: ReactHtmlParser(blog.content),
        // image: file,
        image: "https://property.aiodock.com/fortune/website/Home.png",
        // url:`https://property.aiodock.com/fortune/website/Home.png`
        url: window.location.href,
      }
    : {
        title: "Default Title",
        description: "Default Description",
        image: "Default Image URL",
        url: window.location.href,
      };

  console.log("oPEN gRAPGH tAGS ARE", openGraphTags);
  return (
    <>
      <div>
        {blog ? (
          <div className="single-blog-page pd-top-120 go-top">
            {/* React Helmet async */}
              {/* <Helmet>
                <title>{String(openGraphTags.title)}</title>
                <meta
                  property="og:title"
                  content={String(openGraphTags.title)}
                />
                <meta
                  property="og:description"
                  content={String(openGraphTags.description)}
                />
                <meta
                  property="og:image"
                  content={String(openGraphTags.image)}
                />
                <meta property="og:url" content={openGraphTags.url} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                  name="twitter:title"
                  content={String(openGraphTags.title)}
                />
                <meta
                  name="twitter:description"
                  content={String(openGraphTags.description)}
                />
                <meta
                  name="twitter:image"
                  content={String(openGraphTags.image)}
                />
              </Helmet> */}

            {/* React Helmet */}
            <Helmet>
              <title>{openGraphTags.title}</title>
              <meta property="og:title" content={String(openGraphTags.title)} />
              <meta
                property="og:description"
                content={String(openGraphTags.description)}
              />
              <meta property="og:image" content={String(openGraphTags.image)} />

              <meta property="og:url" content={openGraphTags.url} />

              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:title"
                content={String(openGraphTags.title)}
              />
              <meta
                name="twitter:description"
                content={String(openGraphTags.description)}
              />
              <meta
                name="twitter:image"
                content={String(openGraphTags.image)}
              />
            </Helmet>

            <div className="container-fluid">
              <div
                className="row mx-2"
                // style={{ paddingBottom: "5em" }}
              >
                <div className="col-lg-8 col-sm-12">
                  <div className="blog-details-page-inner">
                    {/* <div className="blog-details-page-inner pb-lg-5"> */}
                    <div className="single-blog-inner bg-none">
                      <div
                        style={{
                          backgroundColor: "#e8e9ee",
                        }}
                      >
                        <div
                          className="ltn__team-details-member-info"
                          style={{ marginLeft: "2em" }}
                        >
                          <h2
                            style={{
                              fontWeight: "800",
                              color: "#1A2B56",
                              paddingTop: "1em",
                            }}
                          >
                            {blog.title}
                          </h2>
                          <h6>
                            <i
                              style={{ color: "#ff5a3c" }}
                              class="far fa-light fa-user mx-2"
                            />
                            {/* {blog.author} */}
                            {blog.author ? blog.author : "Anonymus"} |
                            <i
                              style={{ color: "#ff5a3c" }}
                              className="far fa-calendar-alt mx-2 "
                            />
                            {new Date(blog.date).toDateString()}
                          </h6>
                        </div>

                        <div className="thumb">
                          <img
                            style={{ padding: "10px" }}
                            src={file}
                            alt="img"
                          />
                        </div>
                        <div className="ltn__team-details-member-info  mb-40">
                          <p
                            style={{
                              color: "#1A2B56",
                              margin: "1em",
                            }}
                          >
                            {ReactHtmlParser(blog.content)}
                          </p>
                          {/* Share to facebook, whatsapp or twitter code Starts here */}
                          <div className="container">
                            <div
                              className=" ltn__service-btn go-top container-fluid"
                              // style={{ marginLeft: "1em" }}
                            >
                              <h1
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "0.2em",
                                  fontWeight: "600",
                                  fontSize: "1.5em",
                                  color: "#1A2B56",
                                  // borderBottom: "1px solid #1A2B56",
                                  // color: "rgb(255, 90, 60)",
                                }}
                              >
                                Share Blog
                              </h1>
                            </div>

                            <div
                              className="d-flex"
                              style={{ width: "10%", marginLeft: "0.8em " }}
                              // className="justify-content-end" style={{ marginRight: "1em", width: "10%" }}
                            >
                              <a
                                style={{
                                  flex: "1",
                                  borderRadius: "5px",
                                  // marginRight: "5px",
                                  // letterSpacing: "2px",
                                  padding: 0,
                                  marginBottom: "1em",
                                  // height:"3em"
                                  // fontSize: "2.5em",
                                }}
                                // href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                //   window.location.href
                                // )}`}

                                // href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                //   window.location.href
                                // )}&description=${encodeURIComponent(
                                //   `Check out this blog: ${openGraphTags.title}`
                                // // )}&picture=${encodeURIComponent(blog.image)}`}
                                // )}&picture=${encodeURIComponent(openGraphTags.image)}`}

                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                  openGraphTags.url
                                )}&quote=${encodeURIComponent(
                                  openGraphTags.title
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="theme-btn-facebook btn"
                              >
                                <FaFacebook
                                  style={{ color: "#1877f2", fontSize: "2em" }}
                                />
                                {/* <strong>Facebook</strong> */}
                              </a>

                              <a
                                style={{
                                  flex: "1",
                                  borderRadius: "5px",
                                  padding: 0,
                                  // letterSpacing: "2px",
                                }}
                                // href={`https://wa.me/?text=${encodeURIComponent(
                                //   `Hey, Check out this blog I saw on Propex.pk\n\n${window.location.href}`)}`}
                                href={`https://wa.me/?text=${encodeURIComponent(
                                  `Hey, Check out this blog I saw on Propex.pk\n${blog.title}\n\nFor more details\n${window.location.href}`
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="theme-btn-Whatsapp1 btn"
                              >
                                <FaWhatsapp
                                  style={{ color: "#25D366", fontSize: "2em" }}
                                />
                                {/* <strong>WhatsApp</strong> */}
                              </a>

                              {/* <strong>WhatsApp</strong> */}
                              {/* <a
                            style={{
                              flex: "1",
                              borderRadius: "5px",
                              padding: 0,
                              // marginRight: "5px",
                              letterSpacing: "2px",
                            }}
                            href={`https://wa.me/?text=${encodeURIComponent(
                              window.location.href
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="theme-btn-Whatsapp1 btn"
                          >
                            <FaWhatsapp
                              style={{ color: "#25D366", fontSize: "2.5em" }}
                            />
                          </a> */}

                              {/* <a
                          style={{
                            flex: "1",
                            borderRadius: "5px",
                            padding: 0,
                            letterSpacing: "2px",
                          }}
                          href={`https://www.instagram.com/share?url=${encodeURIComponent(
                            window.location.href
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="theme-btn-instagram btn"
                        >
                          <FaInstagram
                            style={{ color: "#bc2a8d", fontSize: "2.5em" }}
                          />
                          <strong>Instagram</strong>
                        </a> */}

                              <a
                                style={{
                                  flex: "1",
                                  borderRadius: "5px",
                                  padding: 0,
                                  // letterSpacing: "2px",
                                }}
                                // href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                                //   window.location.href
                                // )}`}
                                // href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                //   "Hey, Check out this awesome blog I saw on Propex.pk\n\n" + blog.title + "\n")}&url=${window.location.href}`}

                                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                  `Hey, Check out this awesome blog I saw on Propex.pk:\n\n${blog.title}\n${window.location.href}`
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="theme-btn-twitter btn"
                              >
                                <FaTwitter
                                  style={{ color: "#1DA1F2", fontSize: "2em" }}
                                />
                              </a>
                              {/* <FacebookShareButton
                                url={"https://www.propex.pk/blog-details/122"}
                                quote={blog.title}
                                hashtag="#Propex"
                                className="theme-btn-facebook btn"
                              >
                                <FaFacebook
                                  style={{ color: "#1877f2", fontSize: "2em" }}
                                />
                              </FacebookShareButton>
                              <FacebookShareButton
                                url={"https://www.zameen.com/Property/islamabad_khanna_pul_house_for_sale_in_islamabad-47559798-9020-1.html"}
                                quote={blog.title}
                                hashtag="#Propex"
                                className="theme-btn-facebook btn"
                              >
                                <FaFacebook
                                  style={{ color: "#1877f2", fontSize: "2em" }}
                          
                                />
                              </FacebookShareButton> */}
                            </div>

                            {/* Share to facebook, whatsapp or twitter code Ends here */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <RecentBlogs />
              </div>
            </div>
          </div>
        ) : (
          <div>No Blog Exists</div>
        )}
      </div>
    </>
  );
}

export default BlogDetails;
