import React from "react";
import Navbar from "./global-components/navbar";
import ProductDetailsCommercial from "./shop-components/shop-details-commercial";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import FeaturedPlot from "./section-components/product-slider-v1";
import Thumbnail from "./section-components/thumbnail-commercial";
import FeatureSliderV2 from "./section-components/feature-slider-v2";
const Product_Details = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div>
      <Navbar />
      <ProductDetailsCommercial />
      <FeatureSliderV2 />
      <Thumbnail />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default Product_Details;
