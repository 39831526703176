import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CopyRight extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5" style={{backgroundColor:"#17264D"}}>
			<div className="container-fluid ltn__border-top-2">
				<div className="row">
				<div className="col-md-12 col-12">
					<div className="ltn__copyright-design clearfix">
					{/* <p style={{justifyContent:"center", alignItems:"center", textAlign:"center"}}>All Rights Reserved @ Fortune Group of Investment<span className="current-year" /></p> */}
					{/* <p style={{justifyContent:"center", alignItems:"center", textAlign:"center",}}>All Rights Reserved @ Fortune Investment Group<span className="current-year" /></p> */}
					<p style={{justifyContent:"center", alignItems:"center", textAlign:"center",}}>All Rights Reserved @ Fortune Investment Group 2023</p>
					</div>
				</div>
				{/* <div className="col-md-6 col-12 align-self-center">
					<div className="ltn__copyright-menu text-end">
					<ul className="go-top">
						<li><Link to="/about">Terms &amp; Conditions</Link></li>
						<li><Link to="/about">Claim</Link></li>
						<li><Link to="/about">Privacy &amp; Policy</Link></li>
					</ul>
					</div>
				</div> */}
				</div>
			</div>
			</div>
        )
    }
}


export default CopyRight