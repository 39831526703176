import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import { Fade } from "react-reveal";

import ReactHtmlParser from "react-html-parser";
class BlogSliderV2 extends Component {
  constructor() {
    super();
    this.state = {
      blogs: [],
    };
  }
  componentDidMount() {
    // fetch("https://fortune.aiodock.com/fortune/blog/three").then((result) => {
    // fetch("https://property.aiodock.com/fortune/blog/three").then((result) => {
    fetch("https://property.aiodock.com/fortune/blog/three").then((result) => {
      result.json().then((response) => {
        this.setState({ blogs: response });
      });
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let customClass = this.props.customClass ? this.props.customClass : "";
    let sectionClass = this.props.sectionClass ? this.props.sectionClass : "";
    return (
      <div
        className={"ltn__blog-area pt-115--- go-top " + sectionClass + " pt-20"}
        style={{
          backgroundImage: `url(${publicUrl}assets/img/bg-01.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <>
          {this.state.blogs.length == 0 ? null : (
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-area ltn__section-title-2--- text-center mt-4">
                      <h3 className="" style={{ fontSize: "28px" }}>
                        Leatest News Feeds
                      </h3>

                      <h1
                        style={{ fontWeight: "600", letterSpacing: "0.1em" }}
                        className="section-title ltn__secondary-color"
                      >
                        NEWS &amp; BLOGS
                      </h1>
                    </div>
                  </div>
                </div>

                {/* Blog Item */}
                <div className="row new-blog  ltn__blog-slider-one-active slick-arrow-3 ltn__blog-item-3-normal">
                  {this.state.blogs.map((blog, index) => {
                    return <SingleBlog blog={blog} key={blog.id} />;
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}

function SingleBlog(props) {
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");

  useEffect(() => {
    fetch(
      // "https://fortune.aiodock.com/fortune/blog/image/" + props.blog.image,
      "https://property.aiodock.com/fortune/blog/image/" + props.blog.image,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.blog.datatype + "base64," + response.data);
        setExtension(props.blog.image.split(".")[1]);
      });
  }, []);
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div
      className="col-lg-4 "
      key={props.index}
      // style={{ marginBottom: "-35px" }}
    >
      <Fade bottom duration={1500}>
        <div className="ltn__blog-item ltn__blog-item-3">
          <div className="ltn__blog-img">
            <Link to={"/blog-details/" + props.blog.id}>
              <img src={file} alt="#" />
            </Link>
          </div>
          <div className="ltn__blog-brief">
            {/* <h3 className="ltn__blog-title ltn__secondary-color">
              <Link to={"/blog-details/" + props.blog.id}>
                {props.blog.title}
              </Link>
            </h3> */}
            <h3 className="ltn__blog-title ltn__secondary-color">
              <Link to={"/blog-details/" + props.blog.id}>
                {props.blog.title}
              </Link>
            </h3>

            <div className="">
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-date">
                    <i className="far fa-calendar-alt" />
                    {new Date(props.blog.date).toDateString()}
                  </li>
                </ul>
              </div>
              <p>{ReactHtmlParser(truncateText(props.blog.content, 220))}</p>
              <Link
                style={{
                  color: "white",
                }}
                to={"/blog-details/" + props.blog.id}
              >
                <div
                  // className="ltn__blog-btn w-50"
                  className="ltn__blog-btn"
                  style={{
                    backgroundColor: "#ff5a3c",
                    borderRadius: "10px",
                    height: "40px",
                    width:"40%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginLeft: "70px",
                    margin: "auto",
                  }}
                >
                  <span style={{ color: "#fff" }}>Read more</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default BlogSliderV2;
