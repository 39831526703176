import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Wishlist from "./section-components/wishlist";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import LeadForm from "./section-components/consultancy";

const Consultancy = () => {
  return (
    <div>
      <Navbar />
      <div className="ltn__breadcrumb-area bg-overlay-theme-black-30 consultancy">
        <PageHeader headertitle="Consultancy" style={{color:'white'}} />
      </div>
      <LeadForm />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default Consultancy;
