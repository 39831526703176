import React, { useState, useEffect } from "react";
import Navbar from "./global-components/navbar-v3";
import Banner from "./section-components/banner-v3";
import ProductSliderV1 from "./section-components/product-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import CallToActionV5 from "./section-components/call-to-action-v5";
import Video from "./section-components/Video";
import AboutV7 from "./section-components/about-v7";
import BlogRecentsThree from "./blog-components/blog-slider-v2";
import Marquee from "./global-components/Marquee-v1";
import FeatureSliderV1 from "./section-components/feature-slider-v1";
import RecentSliderV1 from "./section-components/recent-slider-v1";
import { Helmet } from "react-helmet";

const Home_V1 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [imageData, setImageData] = useState("");
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   fetch("https://property.aiodock.com/fortune/home/Home.png")
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Image not found");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setImageData(data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Image fetch error:", error);
  //       setError(error.message);
  //     });
  // }, []);

  useEffect(() => {
    // Function to clear the entire cache when the component mounts
    const clearCache = () => {
      console.log("Clearing cache...");
      if ("caches" in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }
      console.log("Cache cleared.");
    };

    // Call the clearCache function when the component mounts
    clearCache();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Propex | All things DHA</title>
      </Helmet>
      <Navbar />
      <div
        style={{
          display: "flex", // Make the container a flex container
          justifyContent: "center", // Center horizontally
          alignItems: "center",
        }}
        className="home-image ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-20"
        // data-bs-bg={publicUr l + "assets/img/slider/background.jpg"}
        // data-bs-bg={`data:image/png;base64,${imageData}`}

        data-bs-bg={`https://property.aiodock.com/fortune/website/Home.png`}
      >
        <Banner />
      </div>
      <Marquee />
      {/* <AboutV7 /> */}
      {/* <ProductSliderV1 />  */} {/* Updated: Render ProductSliderV1 once */}
      <FeatureSliderV1 />
      <CallToActionV5 />
      <RecentSliderV1 />
      <Video />
      <BlogRecentsThree customClass="section-subtitle-2" />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Home_V1;
