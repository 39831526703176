import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Reveal, Slide } from "react-reveal";
import { Col, Row } from "reactstrap";
import { Card } from "react-bootstrap";
import Thumbnail from "./thumbnail";
function Main() {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [PropertyGhupshupTitle1, setPropertyGhupshupTitle1] =
    React.useState("");
  const [PropertyGhupshupDescription1, setPropertyGhupshupDescription1] =
    React.useState("");
  const [PropertyGhupshupURL1, setPropertyGhupshupURL1] = React.useState("");

  const [PropertyGhupshupTitle2, setPropertyGhupshupTitle2] =
    React.useState("");
  const [PropertyGhupshupDescription2, setPropertyGhupshupDescription2] =
    React.useState("");
  const [PropertyGhupshupURL2, setPropertyGhupshupURL2] = React.useState("");

  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/configure").then((result) => {
    fetch("https://property.aiodock.com/fortune/configure").then((result) => {
      result.json().then((response) => {
        setPropertyGhupshupTitle1(response[0].youtube.PropertyGhupshupTitle1);
        setPropertyGhupshupTitle2(response[0].youtube.PropertyGhupshupTitle2);
        setPropertyGhupshupDescription1(
          response[0].youtube.PropertyGhupshupDescription1
        );
        setPropertyGhupshupDescription2(
          response[0].youtube.PropertyGhupshupDescription2
        );
        setPropertyGhupshupURL1(response[0].youtube.PropertyGhupshupURL1);
        setPropertyGhupshupURL2(response[0].youtube.PropertyGhupshupURL2);
      });
    });
  }, []);

  return (
    <div
      className="ltn__about-us-area pb-30 go-top"
      style={{
        marginTop: "50px",
        backgroundImage: `url(${publicUrl}assets/img/bg-02.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="section-title-area ltn__section-title-2--- text-center">
          <h3 className="" style={{ fontSize: "28px" }}>
            Our Top
          </h3>

          <h1
            style={{ fontWeight: "600", letterSpacing: "0.1em" }}
            className="section-title ltn__secondary-color"
          >
            VIDEOS
          </h1>
        </div>
        <Card
          className="mb-2"
          style={{
            borderWidth: 0,
            borderColor: "transparent",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "transparent",
          }}
        >
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div className="mt-3 mx-2">
                <Link style={{ flex: 1 }}>
                  <iframe
                    width="560"
                    height="200"
                    src={PropertyGhupshupURL1}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              sm={12}
              style={{ borderBottom: "2px solid black" }}
            >
              <Card.Body style={{ flex: 1 }}>
                <Card.Title style={{ fontWeight: "700", color: "#000080" }}>
                  {PropertyGhupshupTitle1}
                </Card.Title>
                {PropertyGhupshupDescription1}
                {/* <hr
                  style={{
                    color: "#000080",
                    fontWeight: "bold",
                    borderWidth: "2px",
                    borderColor: "#000080",
                  }}
                /> */}
              </Card.Body>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            borderWidth: 0,
            borderColor: "transparent",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "transparent",
          }}
        >
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div className="mt-3 mx-2">
                <Link style={{ flex: 1 }}>
                  <iframe
                    width="560"
                    height="200"
                    src={PropertyGhupshupURL2}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              sm={12}
              style={{ borderBottom: "2px solid black" }}
            >
              <Card.Body style={{ flex: 1 }}>
                <Card.Title style={{ fontWeight: "700", color: "#000080" }}>
                  {PropertyGhupshupTitle2}
                </Card.Title>

                {PropertyGhupshupDescription2}

                {/* <hr
                  style={{
                    color: "#000080",
                    fontWeight: "bold",
                    borderWidth: "2px",
                    borderColor: "#000080",
                    // position: "absolute",
                  }}
                /> */}
              </Card.Body>
            </Col>
          </Row>
        </Card>

        {/* <div style={{marginTop: "5em", }}>
      <Thumbnail />
      <Thumbnail />
      </div> */}

        {/* <div
          className="btn-wrapper"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            href="https://www.youtube.com/@PropertyGupshup"
            target="_blank"
            rel="noopener noreferrer"
            style={{ borderRadius: "5px", marginTop: "" }}
            className="theme-btn-1 btn btn-effect-1"
          >
            Watch More
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Main;
