import React from "react";
import Navbar from "./global-components/navbar";
import ProductDetailsResidential from "./shop-components/shop-details-residential";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import FeaturedPlot from "./section-components/product-slider-v1";
import Thumbnail from "./section-components/thumbnail-residential";
import BlogRecentsThree from "./blog-components/blog-slider-v2";
import FeatureSliderV1 from "./section-components/feature-slider-v1";

const Product_Details_Residential = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'
  return (
    <div>
      <Navbar />
      <ProductDetailsResidential />
      <FeatureSliderV1 />
      <Thumbnail/>
      {/* <BlogRecentsThree customClass="section-subtitle-2" /> */}
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default Product_Details_Residential;
