import React, { useState, useEffect } from "react";
import parse from "html-react-parser";

import { Card, Row, Col } from "react-bootstrap";
function Thumbnail() {
  const [videoLinks, setVideoLinks] = useState([]);
  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/configure").then((result) => {
    fetch("https://property.aiodock.com/fortune/configure").then((result) => {
      result.json().then((response) => {
        setVideoLinks(response);
      });
    });
  }, []);

  return (
    <div className="container-fluid">
      {videoLinks.map((link) => (
        <div>
        <Row className="mb-5 mx-4">
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Flat1}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  // height="315"
                  height="250"
                  src={link.youtube.Flat2}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Flat3}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Flat4}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            {/* <Col key={link.id} xl={6} md={6} sm={12} xs={12}>
            <Card>
              <iframe
                width="560"
                height="315"
                src={link.youtube.Residential3}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Card>
          </Col> */}
          </Row>
        {/* <Row
          className="mb-5 mx-4"
          // style={{paddingLeft:"8em", paddingRight:"8em"}}
        >
          <Col key={link.id} xl={1} md={1} sm={12} xs={12}></Col>
          <Col key={link.id} xl={5} md={5} sm={12} xs={12}>
            <Card>
              <iframe
                // width="570"
                height="450"
                src={link.youtube.House3}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Card>
          </Col>
          <Col key={link.id} xl={5} md={5} sm={12} xs={12}>
            <Card>
              <iframe
                // width="570"
                height="450"
                src={link.youtube.Residential1}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Card>
          </Col>
        </Row> */}
        </div>
      ))}
    </div>
  );
}

export default Thumbnail;
