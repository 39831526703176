import React, { Component } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Row,
  FormGroup,
  Form,
  Label,
  Input,
  FormText,
} from "reactstrap";

export default function LeadForm() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    investmentAreas: [],
    investmentPurpose: [],
    investmentType: [],
    PropertyDetails: "",
    transactionAvailability: "",
    installmentTime: "",
    installmentPlan: "",
    monthlyIncome: "",
    monthlySavings: "",
    monthlyInvestment: "",
    phoneNumber: "",
    contactNumber: "",
    Address: "",
    MiscellaneousInformation: "",
    YourPlan: "",
    City: "",
    zipCode: "",
    Province: "",
  });
  const [result, setResult] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => response.json())
      .then((data) => {
        setResult(data);
        setForm({
          name: "",
          email: "",
          subject: "",
          investmentAreas: [],
          investmentPurpose: [],
          investmentType: [],
          installmentTime: "",
          transactionAvailability: "",
          installmentPlan: "",
          PropertyDetails: "",
          monthlyIncome: "",
          monthlySavings: "",
          monthlyInvestment: "",
          phoneNumber: "",
          contactNumber: "",
          Address: "",
          MiscellaneousInformation: "",
          YourPlan: "",
          City: "",
          zipCode: "",
          Province: "",
        });
      })
      .catch(() => {
        setResult({
          success: false,
          message: "Something went wrong. Try again later",
        });
      });
  };

  return (
    <div
      className="container"
      style={{
        boxShadow:
          "0 8px 20px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      <div className="mt-5 pt-3">
        <h2
          className="bg-primary p-3 "
          style={{ color: "white", fontSize: "30px", textAlign: "center" }}
        >
          WE CAN HELP YOU GROW REAL ESTATE INVESTMENTS 10X FASTER
        </h2>
      </div>
      {result && (
        <p className={`${result.success ? "success" : "error"}`}>
          {result.message}
        </p>
      )}

      <Form className="p-3 mb-5" onSubmit={handleSubmit}>
        <h2
          className="bg-secondary "
          style={{ color: "white", fontSize: "24px", textAlign: "center" }}
        >
          Personal Information
        </h2>
        <Row>
          <Col md={6} xs={11}>
            <FormGroup>
              <Label for="name">Name:</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={form.name}
                onChange={(e) => {
                  setForm({
                    ...form,
                    name: e.target.value,
                  });
                }}
                placeholder="Enter your Name."
              />
              <FormText>Enter Your Full Name.</FormText>
            </FormGroup>
          </Col>
          <Col md={6} xs={11}>
            <FormGroup>
              <Label for="Email">Email: </Label>
              <Input
                id="Email"
                name="email"
                value={form.email}
                placeholder="Enter your email."
                type="email"
                onChange={(e) => {
                  setForm({
                    ...form,
                    email: e.target.value,
                  });
                }}
              />
              <FormText>Enter Your Valid Email Address.</FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={6}>
            <FormGroup>
              <Label for="monthlyIncome">Monthly Income</Label>
              <Input
                id="monthlyIncome"
                name="monthlyIncome"
                value={form.monthlyIncome}
                onChange={(e) => {
                  setForm({
                    ...form,
                    monthlyIncome: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>

          <Col md={4} xs={6}>
            <FormGroup>
              <Label for="monthlySavings">Monthly Savings</Label>
              <Input
                id="monthlySavings"
                name="monthlySavings"
                value={form.monthlySavings}
                onChange={(e) => {
                  setForm({
                    ...form,
                    monthlySavings: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>

          <Col md={4} xs={6}>
            <FormGroup>
              <Label for="monthlyInvestment">Monthly Investment</Label>
              <Input
                id="monthlyInvestment"
                name="monthlyInvestment"
                value={form.monthlyInvestment}
                onChange={(e) => {
                  setForm({
                    ...form,
                    monthlyInvestment: e.target.value,
                  });
                }}
              />
            </FormGroup>
            <FormText>How much money you want to Invest ?</FormText>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={6}>
            <FormGroup>
              <Label for="Phone">Phone #</Label>
              <Input
                id="Phone"
                name="phone"
                value={form.phoneNumber}
                placeholder="Enter your phone number."
                type="text"
                onChange={(e) => {
                  setForm({
                    ...form,
                    phoneNumber: e.target.value,
                  });
                }}
              />
              <FormText>With Country Code.</FormText>
            </FormGroup>
          </Col>
          <Col md={4} xs={5}>
            <FormGroup>
              <Label for="Contact">Contact #</Label>
              <Input
                id="Contact"
                name="contact"
                value={form.contactNumber}
                placeholder="Optional (if any)"
                type="text"
                onChange={(e) => {
                  setForm({
                    ...form,
                    contactNumber: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="Address">Address</Label>
          <Input
            id="Address"
            name="address"
            value={form.Address}
            placeholder="1234 Main St"
            onChange={(e) => {
              setForm({
                ...form,
                Address: e.target.value,
              });
            }}
          />
        </FormGroup>
        <Row>
          <Col md={6} xs={4}>
            <FormGroup>
              <Label for="City">City</Label>
              <Input
                id="City"
                name="city"
                value={form.City}
                onChange={(e) => {
                  setForm({
                    ...form,
                    City: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4} xs={4}>
            <FormGroup>
              <Label for="Province">Province</Label>
              <Input
                id="Province"
                name="province"
                value={form.Province}
                onChange={(e) => {
                  setForm({
                    ...form,
                    Province: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md={2} xs={4}>
            <FormGroup>
              <Label for="Zip">Zip</Label>
              <Input
                id="Zip"
                name="zip"
                value={form.zipCode}
                onChange={(e) => {
                  setForm({
                    ...form,
                    zipCode: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <h2
          className="bg-secondary "
          style={{ color: "white", fontSize: "24px", textAlign: "center" }}
        >
          Investment Plans
        </h2>
        <Row>
          <Col md={6} xs={12}>
            <Label for="investmentAreas">Select Areas for Investment:</Label>{" "}
            <br />
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Islamabad"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const areas = form.investmentAreas.slice();
                  if (checked) {
                    areas.push(e.target.value);
                  } else {
                    const index = areas.indexOf(e.target.value);
                    if (index !== -1) {
                      areas.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentAreas: areas,
                  });
                }}
              />
              <Label check>Islamabad</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Lahore"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const areas = form.investmentAreas.slice();
                  if (checked) {
                    areas.push(e.target.value);
                  } else {
                    const index = areas.indexOf(e.target.value);
                    if (index !== -1) {
                      areas.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentAreas: areas,
                  });
                }}
              />
              <Label check>Lahore</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Karachi"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const areas = form.investmentAreas.slice();
                  if (checked) {
                    areas.push(e.target.value);
                  } else {
                    const index = areas.indexOf(e.target.value);
                    if (index !== -1) {
                      areas.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentAreas: areas,
                  });
                }}
              />
              <Label check>Karachi</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Multan"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const areas = form.investmentAreas.slice();
                  if (checked) {
                    areas.push(e.target.value);
                  } else {
                    const index = areas.indexOf(e.target.value);
                    if (index !== -1) {
                      areas.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentAreas: areas,
                  });
                }}
              />
              <Label check>Multan</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Gawadar"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const areas = form.investmentAreas.slice();
                  if (checked) {
                    areas.push(e.target.value);
                  } else {
                    const index = areas.indexOf(e.target.value);
                    if (index !== -1) {
                      areas.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentAreas: areas,
                  });
                }}
              />
              <Label check>Gawadar</Label>
            </FormGroup>
            <FormText>Select all Applicable</FormText>
          </Col>
          <Col md={6} xs={12}>
            <Label for="investmentPurpose">Purpose of Investment:</Label> <br />
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Rental Returns"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const purpose = form.investmentPurpose.slice();
                  if (checked) {
                    purpose.push(e.target.value);
                  } else {
                    const index = purpose.indexOf(e.target.value);
                    if (index !== -1) {
                      purpose.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentPurpose: purpose,
                  });
                }}
              />
              <Label check>Rental Returns</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Capital Gains"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const purpose = form.investmentPurpose.slice();
                  if (checked) {
                    purpose.push(e.target.value);
                  } else {
                    const index = purpose.indexOf(e.target.value);
                    if (index !== -1) {
                      purpose.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentPurpose: purpose,
                  });
                }}
              />
              <Label check>Capital Gains</Label>
            </FormGroup>{" "}
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Other Reasons"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const purpose = form.investmentPurpose.slice();
                  if (checked) {
                    purpose.push(e.target.value);
                  } else {
                    const index = purpose.indexOf(e.target.value);
                    if (index !== -1) {
                      purpose.splice(index, 1);
                    }
                  }

                  setForm({
                    ...form,
                    investmentPurpose: purpose,
                  });
                }}
              />
              <Label check>Other Reasons</Label>
            </FormGroup>
            <FormText>Select all Applicable</FormText>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={6} xs={12}>
            <Label for="installmentPlans">Installment Plans:</Label> <br />
            <FormGroup check inline>
              <Input
                name="plan"
                type="radio"
                value="Yes"
                onChange={(e) =>
                  setForm({ ...form, installmentPlan: e.target.value })
                }
              />
              <Label check> Yes</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                name="plan"
                type="radio"
                value="No"
                onChange={(e) =>
                  setForm({ ...form, installmentPlan: e.target.value })
                }
              />
              <Label check> No</Label>
            </FormGroup>
          </Col>
          <Col md={6} xs={12}>
            <Label for="investmentTimeFrame">Installment Time Frame:</Label>{" "}
            <br />
            <FormGroup check inline>
              <Input
                name="time"
                type="radio"
                value="1 Year"
                onChange={(e) => {
                  setForm({
                    ...form,
                    installmentTime: e.target.value,
                  });
                }}
              />{" "}
              <Label check> 1 Year</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                name="time"
                type="radio"
                value="2 Years"
                onChange={(e) => {
                  setForm({
                    ...form,
                    installmentTime: e.target.value,
                  });
                }}
              />
              <Label check> 2 Years</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                name="time"
                type="radio"
                value="3-4 Years"
                onChange={(e) => {
                  setForm({
                    ...form,
                    installmentTime: e.target.value,
                  });
                }}
              />{" "}
              <Label check> 3-4 Years</Label>
            </FormGroup>{" "}
            <br />
            <FormGroup check inline>
              <Input
                name="time"
                type="radio"
                value="4-6 Years"
                onChange={(e) => {
                  setForm({
                    ...form,
                    installmentTime: e.target.value,
                  });
                }}
              />{" "}
              <Label check> 4-6 Years</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                name="time"
                type="radio"
                value="6-10 Years"
                onChange={(e) => {
                  setForm({
                    ...form,
                    installmentTime: e.target.value,
                  });
                }}
              />{" "}
              <Label check> 6-10 Years</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                name="time"
                type="radio"
                value="10-20 Years"
                onChange={(e) => {
                  setForm({
                    ...form,
                    installmentTime: e.target.value,
                  });
                }}
              />{" "}
              <Label check> 10-20 Years</Label>
            </FormGroup>
            <FormText>Pick Applicable</FormText>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={6} xs={12}>
            <Label for="investmentAvailability">Availability:</Label> <br />
            <FormGroup check inline>
              <Input
                name="radio"
                type="radio"
                value="Monthly"
                onChange={(e) => {
                  setForm({
                    ...form,
                    transactionAvailability: e.target.value,
                  });
                }}
              />
              <Label check>Monthly</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                name="radio"
                type="radio"
                value="Bi-Annual"
                onChange={(e) => {
                  setForm({
                    ...form,
                    transactionAvailability: e.target.value,
                  });
                }}
              />
              <Label check>Bi-Annual</Label>
            </FormGroup>{" "}
            <FormGroup check inline>
              <Input
                name="radio"
                type="radio"
                value="Yearly"
                onChange={(e) => {
                  setForm({
                    ...form,
                    transactionAvailability: e.target.value,
                  });
                }}
              />
              <Label check>Yearly</Label>
            </FormGroup>{" "}
            <br />
            <FormGroup check inline>
              <Input
                name="radio"
                type="radio"
                value="2-5"
                onChange={(e) => {
                  setForm({
                    ...form,
                    transactionAvailability: e.target.value,
                  });
                }}
              />
              <Label check>2-5 Years</Label>
            </FormGroup>
            <FormText>Your Availability for Transactions.</FormText>
          </Col>
          <Col md={6} xs={12}>
            <Label for="investmentType">Preferred Investment Type:</Label>{" "}
            <br />
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Files & Plots"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Files & Plots</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Apartments & Highrise"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Apartments & Highrise</Label>
            </FormGroup>{" "}
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Homes"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Homes</Label>
            </FormGroup>
            <br />
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Hotel & Serviced Apartments"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Hotel & Serviced Apartments</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Commercial Buildings"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Commercial Buildings</Label>
            </FormGroup>
            <br />
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Commercial Offices"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Commercial Offices</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Offices"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Offices</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                value="Any Other"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const type = form.investmentType.slice();
                  if (checked) {
                    type.push(e.target.value);
                  } else {
                    const index = type.indexOf(e.target.value);
                    if (index !== -1) {
                      type.splice(index, 1);
                    }
                  }
                  setForm({
                    ...form,
                    investmentType: type,
                  });
                }}
              />
              <Label check>Any Other</Label>
            </FormGroup>
            <FormText>Select all Applicable</FormText>
          </Col>
        </Row>{" "}
        <FormGroup>
          <Label for="propertyDetails"> Property Details:</Label>
          <Input
            id="propertyDetails"
            name="propertyDetails"
            type="textarea"
            value={form.PropertyDetails}
            rows="4"
            onChange={(e) => {
              setForm({
                ...form,
                PropertyDetails: e.target.value,
              });
            }}
          />
          <FormText>
            Details of all Properties you owned, including Date of Purchased and
            Price. (optional)
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="yourPlan">Your Plan:</Label>
          <Input
            id="yourPlan"
            name="yourPlan"
            type="textarea"
            value={form.YourPlan}
            rows="4"
            onChange={(e) => {
              setForm({
                ...form,
                YourPlan: e.target.value,
              });
            }}
          />
          <FormText>Tell us about your Goals from these Investments.</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="otherInfo">Miscellanous Information: </Label>
          <Input
            id="otherInfo"
            name="otherInfo"
            type="textarea"
            rows="4"
            value={form.MiscellaneousInformation}
            onChange={(e) => {
              setForm({
                ...form,
                MiscellaneousInformation: e.target.value,
              });
            }}
          />
          <FormText>
            Anything You Want us to consider to Incorporate in your Plan.
          </FormText>
        </FormGroup>
        <br />
        <Button type="submit" color="primary">
          Submit
        </Button>
      </Form>
    </div>
  );
}
