import React, { useState, useEffect } from "react";
import parse from "html-react-parser";

import { Card, Row, Col } from "react-bootstrap";
function Thumbnail() {
  const [videoLinks, setVideoLinks] = useState([]);
  useEffect(() => {
    // fetch("https://fortune.aiodock.com/fortune/configure").then((result) => {
    fetch("https://property.aiodock.com/fortune/configure").then((result) => {
      result.json().then((response) => {
        setVideoLinks(response);
      });
    });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        {videoLinks.map((link) => (
          <Row className="mb-5 mx-4">
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Residential1}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Residential2}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Residential3}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Commercial1}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            {/* <Col key={link.id} xl={6} md={6} sm={12} xs={12}>
            <Card>
              <iframe
                width="560"
                height="315"
                src={link.youtube.Residential3}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Card>
          </Col> */}
          </Row>
        ))}
      </div>
      <div className="container-fluid">
        {videoLinks.map((link) => (
          <Row className="mb-5 mx-4">
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.Commercial2}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  // height="315"
                  height="250"
                  src={link.youtube.House1}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.House2}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            <Col key={link.id} xl={3} md={3} sm={12} xs={12}>
              <Card>
                <iframe
                  // width="570"
                  height="250"
                  src={link.youtube.House3}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Card>
            </Col>
            {/* <Col key={link.id} xl={6} md={6} sm={12} xs={12}>
            <Card>
              <iframe
                width="560"
                height="315"
                src={link.youtube.Residential3}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Card>
          </Col> */}
          </Row>
        ))}
        {/* <div className="">
          <div className="btn-wrapper go-top " />
          <div style={{ fontWeight: "600" }}>
            <Link
              style={{ borderRadius: "5px" }}
              className="btn  btn-white"
              to="/map"
            >
              View Maps <i className="icon-next" />
            </Link>
          </div>
        </div> */}
        <div
          className="btn-wrapper go-top mb-3"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            className="btn  btn-white"
            href="https://www.youtube.com/@PropertyGupshup"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              borderRadius: "5px",
              marginTop: "",
              color: "#fff",
              backgroundColor: "#FF5A3C",
            }}
            // className="theme-btn-1 btn btn-effect-1"
          >
            Watch More
          </a>
        </div>
      </div>
    </div>
  );
}

export default Thumbnail;
