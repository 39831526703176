import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";

function MarqueeV1() {
  const [Headline1, setHeadline1] = useState("");
  const [Headline2, setHeadline2] = useState("");
  const [Headline3, setHeadline3] = useState("");
  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/configure", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // Headlines
        setHeadline1(response[0].youtube.Headline1);
        setHeadline2(response[0].youtube.Headline2);
        setHeadline3(response[0].youtube.Headline3);
      });
  }, []);

  return (
    <div
      style={{
        height: "3em",
        backgroundColor: "#dcdcdc",
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Marquee direction="left" style={{ color: "#FF5A3C" }} speed={100} pauseOnHover={true}>
        {Headline1} &emsp;&emsp;&emsp; {Headline2} &emsp;&emsp;&emsp; {Headline3} &emsp;&emsp;&emsp;
        {/*  &nbsp;  is used for adding a space when a headline is finished */}
        {/* Regular space: &nbsp;
            Two spaces gap: &ensp;
            Four spaces gap: &emsp;
 */}
      </Marquee>
    </div>
  );
}

export default MarqueeV1;
