import React, { useState, useEffect } from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import { TbZoomReset } from "react-icons/tb";
import { FileDownload } from "@mui/icons-material";
import Container from "@mui/material/Container";
// Map Images
import dhaEmaar from "../pdf/Dha-Emaar.png";
import phase_01 from "../pdf/Dha-Emaar.png";
import phase_01_sectorF from "../pdf/DHA PHASE 1 (Sector F + Defence Villa).png";
import phase_02 from "../pdf/DHA Phase 02.jpg";
// import phase_03_Overall from "../pdf/DHA Phase 03 Overall.jpg";
import phase_03_serene from "../pdf/DHA PHASE 3(SERENE CITY).png";
import phase_03_allocation from "../pdf/DHA PHASE 3(SERENE CITY).png";
import phase_03_new_pindi from "../pdf/DHA 3 (New Pindi).jpg";
import phase_04_old from "../pdf/DHA Phase 04 (Old).jpg";
import phase_04_overseas from "../pdf/DHA Phase 04 (AllSectors).jpg";
import phase_04_new from "../pdf/DHA Phase 4 (New).jpg";
import phase_05 from "../pdf/DHA PHASE 5.png";
import phase_06_sectorA from "../pdf/DHA Phase 06 (Sector A & A1).jpg";
import phase_06_sectorB3 from "../pdf/DHA PHASE 06 (Sector B3, C1, C2,C3 & C3-Ext).png";

// Map PDFs
// import dhaEmaar_pdf from "../pdf/pdfs/DHA EMAAR.pdf";
// import phase_01_pdf from "../pdf/pdfs/DHA Phase 1.pdf";
// import phase_01_sectorF_pdf from "../pdf/pdfs/DHA PHASE 1 (Sector F + Defence Villa).pdf";
// import phase_02_pdf from "../pdf/pdfs/DHA Phase 2.pdf";
// import phase_03_serene_pdf from "../pdf/pdfs/DHA PHASE 3(SERENE CITY).pdf";
// import phase_03_allocation_pdf from "../pdf/pdfs/DHA 3 (Allocations).pdf";
// import phase_03_new_pindi_pdf from "../pdf/pdfs/DHA 3 (New Pindi).pdf";
// import phase_04_old_pdf from "../pdf/pdfs/DHA 4(Old).pdf";
// import phase_04_overseas_pdf from "../pdf/pdfs/DHA Phase 4 (Overseas Sector).pdf";
// import phase_04_new_pdf from "../pdf/pdfs/DHA Phase 04 (New).pdf";
// import phase_05_pdf from "../pdf/pdfs/DHA PHASE 5.pdf";
// import phase_06_sectorA_pdf from "../pdf/pdfs/DHA Phase 6(Sector A _ A-1).pdf";
// import phase_06_sectorB3_pdf from "../pdf/pdfs/DHA PHASE 6(Sector C, C3-Ext).pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FaSearchPlus, FaSearchMinus } from "react-icons/fa";

function MapModal(props) {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <React.Fragment>
      <Container fluid>
        <ListGroup variant="flush">
          <ListGroup.Item style={{ marginBottom: "5px", padding: "0" }}>
            <Row>
              <Col lg={7} xs={7} md={7} sm={7}>
                <p>{props.title}</p>
              </Col>
              {/* <Col lg={3} xs={3} md={3} sm={3}>
                {" "}
                <Button
                  onClick={onOpenModal}
                  variant="contained"
                  // color="success"
                  style={{ backgroundColor: "#ff5a3c", color: "white" }}
                  size="small"
                >
                  {<Visibility />}
                </Button>
              </Col> */}
              <Col lg={4} xs={4} md={4} sm={4}>
                <a
                  href={props.pdfSrc}
                  download={props.title}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="outlined"
                    // color="success"
                    style={{ borderColor: "#ff5a3c", color: "#ff5a3c" }}
                    size="small"
                    startIcon={<FileDownload />}
                  >
                    Download
                  </Button>
                </a>
              </Col>
              <Col lg={1} xs={1} md={1} sm={1}></Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>

        <Modal open={open} onClose={onCloseModal} center>
          <h2 style={{ color: "#FF5A3C" }}>{props.title}</h2>

          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <div className="tools mb-2">
                  <button
                    variant="secondary"
                    className="mx-1"
                    size="sm"
                    onClick={() => zoomIn()}
                  >
                    <FaSearchPlus sty />
                  </button>
                  <button
                    variant="secondary"
                    className="mx-1"
                    onClick={() => zoomOut()}
                    size="sm"
                  >
                    <FaSearchMinus />
                  </button>
                  <button
                    variant="secondary"
                    onClick={() => resetTransform()}
                    size="sm"
                  >
                    <TbZoomReset />
                  </button>
                </div>
                <TransformComponent>
                  <img src={props.imageSrc} alt={props.title} />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Modal>
      </Container>
    </React.Fragment>
  );
}

function Map() {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="container">
      <div className="container mx-50">
        <Row>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA EMAAR"
              imageSrc={dhaEmaar}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA 05 EMAAR.pdf"}
              // pdfSrc={publicUrl + 'DHA EMAAR.pdf'}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 01"
              imageSrc={phase_01}
              // pdfSrc={phase_01_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 1.pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 01 (Sector F + Defence Villa)"
              imageSrc={phase_01_sectorF}
              // pdfSrc={phase_01_sectorF_pdf}
              pdfSrc={
                publicUrl +
                "assets/pdf/pdfs/DHA Phase 01 (Sector F + Defence Villa).pdf"
              }
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 02"
              imageSrc={phase_02}
              // pdfSrc={phase_02_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 2.pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 03 (Overall)"
              // imageSrc={phase_03_(Overall)}
              // pdfSrc={phase_02_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 3.pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 03 (Serene City)"
              imageSrc={phase_03_serene}
              // pdfSrc={phase_03_serene_pdf}
              pdfSrc={
                publicUrl + "assets/pdf/pdfs/DHA Phase 03 (Serene City).pdf"
              }
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 03 (Allocation)"
              imageSrc={phase_03_allocation}
              // pdfSrc={phase_03_allocation_pdf}
              pdfSrc={
                publicUrl + "assets/pdf/pdfs/DHA Phase 03 (Army Allocation).pdf"
              }
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 03 (New Pindi)"
              imageSrc={phase_03_new_pindi}
              // pdfSrc={phase_03_new_pindi_pdf}
              pdfSrc={
                publicUrl + "assets/pdf/pdfs/DHA Phase 03 (New Pindi).pdf"
              }
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 04 (Old)"
              imageSrc={phase_04_old}
              // pdfSrc={phase_04_old_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 04(Old).pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 04 (Overseas)"
              imageSrc={phase_04_overseas}
              // pdfSrc={phase_04_overseas_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 04 (Overseas).pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 04 (New)"
              imageSrc={phase_04_new}
              // pdfSrc={phase_04_new_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 4.pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 05"
              imageSrc={phase_05}
              // pdfSrc={phase_05_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 5.pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 06 Sector A"
              imageSrc={phase_06_sectorA}
              // pdfSrc={phase_06_sectorA_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 06 Sector A.pdf"}
            />
          </Col>
          <Col xs={12} lg={6} md={6}>
            <MapModal
              title="DHA Phase 06(Sector B3, C1, C2, C3, C3-Ext)"
              imageSrc={phase_06_sectorB3}
              // pdfSrc={phase_06_sectorB3_pdf}
              pdfSrc={publicUrl + "assets/pdf/pdfs/DHA Phase 6.pdf"}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Map;
