import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import SidebarV1 from "./sidebar-v1";
import ReactHtmlParser from "react-html-parser";

function BlogGridV1() {
  let imagealt = "image";
  const [blogs, setBlogs] = useState([]);

  // Sort the blogs by ID in descending order
  // useEffect(() => {
  //   // fetch("https://fortune.aiodock.com/fortune/blog").then((result) => {
  //   fetch("https://property.aiodock.com/fortune/blog").then((result) => {
  //     result.json().then((response) => {
  //       setBlogs(response);
  //     });
  //   });
  // }, []);

  useEffect(() => {
    // fetch("https://property.aiodock.com/fortune/blog")
    fetch("https://property.aiodock.com/fortune/blog").then((result) => {
      // fetch("https://property.aiodock.com/fortune/blog").then((result) => {
      result.json().then((response) => {
        // Sort the blogs by ID in descending order
        response.sort((a, b) => b.id - a.id);
        setBlogs(response);
      });
    });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div
      className="ltn__blog-area ltn__blog-item-3-normal mb-5 go-top d-flex"
      style={{
        marginTop: "100px",
        backgroundImage: `url(${publicUrl}assets/img/bg-01.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container-fluid">
        {/* <div className="row"> */}
        <div style={{ marginLeft: "2%", marginRight: "2%" }}>
          {/* Blog Item */}
          <Row>
            <Col lg={8} md={8} sm={12} xs={12}>
              <PaginatedItems itemsPerPage={4} items={blogs} />
            </Col>

            <SidebarV1 />
          </Row>
        </div>
      </div>
    </div>
  );
}

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((blogs, index) => (
          <div key={blogs.id} className=" d-flex">
            <SingleBlog blog={blogs} />
          </div>
        ))}
    </>
  );
}
function PaginatedItems({ itemsPerPage, items }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        className="pagination"
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        pageCount={pageCount}
        previousLabel="Back"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

function SingleBlog(props) {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");

  useEffect(() => {
    fetch(
      "https://property.aiodock.com/fortune/blog/image/" + props.blog.image,
      // "https://property.aiodock.com/fortune/blog/image/" + props.blog.image,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.blog.datatype + "base64," + response.data);
        setExtension(props.blog.image.split(".")[1]);
      });
  }, []);
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  return (
    <div>
      <div
        key={props.blog.id}
        className="ltn__blog-item ltn__blog-item-3"
        style={{ margin: "10px", height: "" }}
      >
        <div
          style={
            {
              // marginBottom: "10px",
            }
          }
        >
          <Card
            className="mb-5"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Row xl={12} md={12} lg={12} xs={12}>
              <Col lg={6} md={4} sm={12} xs={12}>
                <Link
                  key={props.blog._id}
                  to={`/blog-details/${props.blog.id}`}
                  style={{ flex: 1 }}
                >
                  <Card.Img
                    className=""
                    style={{ width: "100%", height: "100%" }}
                    variant="top"
                    src={file}
                  />
                </Link>
              </Col>
              <Col lg={6} md={8} sm={12} xs={12}>
                <Card.Body style={{ flex: 1 }}>
                  <Card.Title>
                    <h3 className="ltn__blog-title">
                      <Link
                        key={props.blog._id}
                        to={`/blog-details/${props.blog.id}`}
                      >
                        {props.blog.title}
                      </Link>
                    </h3>
                    <h6 style={{ fontSize: "0.7em", marginLeft: "-0.5em" }}>
                      <i
                        style={{ color: "#ff5a3c" }}
                        class="far fa-light fa-user mx-2"
                      />
                      {props.blog.author ? props.blog.author : "Anonymus"} |
                      <i
                        style={{ color: "#ff5a3c" }}
                        className="far fa-calendar-alt mx-2 "
                      />
                      {new Date(props.blog.date).toDateString()}
                    </h6>
                  </Card.Title>
                  <Card.Text>
                    {ReactHtmlParser(truncateText(props.blog.content, 300))}
                  </Card.Text>
                  <div
                    className="ltn__blog-meta-btn"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="ltn__blog-btn"
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Link
                        className="read-more"
                        key={props.blog._id}
                        to={`/blog-details/${props.blog.id}`}
                      >
                        Read More <i className="la la-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default BlogGridV1;
{
  /* <div className="ltn__blog-img"></div>
      <div className="">
        <h3 className="ltn__blog-title">
          <Link key={props.blog._id} to={`/blog-details/${props.blog.id}`}>
            {props.blog.title}
          </Link>
        </h3>
        <div className="ltn__blog-meta-btn">
          <div className="ltn__blog-meta">
            <ul>
              <li className="ltn__blog-date">
                <i className="far fa-calendar-alt" />
                {new Date(props.blog.date).toDateString()}
              </li>
            </ul>
          </div>
          <div className="ltn__blog-btn">
            <Link
              className="read-more"
              key={props.blog._id}
              to={`/blog-details/${props.blog.id}`}
            >
              Read More <i className="la la-arrow-right" />
            </Link>
          </div>
        </div>
      </div>
    </div> */
}
