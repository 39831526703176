import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Slider from "react-slick";
import CallIcon from "@mui/icons-material/Call";
import { FaWhatsapp } from "react-icons/fa";
import { Card } from "react-bootstrap";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "none",
        margin: "0 20px",
        fontSize: "24px",
      }}
      onClick={onClick}
    >
      <i className="fa fa-chevron-right" aria-hidden="true"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "none",
        margin: "0 20px",
        fontSize: "24px",
      }}
      onClick={onClick}
    >
      <i className="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
  );
}

class RecentSliderV1 extends Component {
  constructor() {
    super();
    this.state = {
      featurePlots: [],
    };
  }

  componentDidMount() {
    fetch("https://property.aiodock.com/fortune/plot/recent", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        this.setState({ featurePlots: response });
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        {this.state.featurePlots.length === 0 ? null : (
          <div
            style={{
              backgroundImage: `url(${publicUrl}assets/img/bg-02.png)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="ltn__product-slider-area ltn__product-gutter plr--7">
              <div
                className="container-fluid"
                // className="container"
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ltn__section-title-2--- text-center">
                      {/* <h6 className="section-subtitle section-subtitle-2 ">
                        Properties
                      </h6> */}
                      <h1
                        style={{
                          fontWeight: "600",
                          letterSpacing: "0.1em",
                          // marginBottom:"-1.5em"
                        }}
                        className=" ltn__secondary-color"
                      >
                        RECENT PROPERTIES
                      </h1>
                    </div>
                  </div>
                </div>
                {/* <Fade bottom duration={1500}> */}
                <Slider
                  style={{ display: "flex", alignItems: "center" }}
                  {...settings}
                >
                  {/* ltn__product-item */}
                  {this.state.featurePlots.map((plot, index) => {
                    return (
                      <Plot
                        category={plot.category}
                        key={plot.id}
                        city={plot.city}
                        id={plot.id}
                        description={plot.description}
                        datatype={plot.datatype}
                        location={plot.location}
                        image={plot.image}
                        price={plot.price}
                        size={plot.size}
                        title={plot.title}
                        Plotcategory={plot.details.category}
                        phase={plot.phase}
                        sector={plot.sector}
                        callnow={plot.details.callnow}
                        // image={plot.image}
                      />
                    );
                  })}
                </Slider>

                {/* </Fade> */}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function Plot(props) {
  const [file, setFile] = React.useState("");
  const [extension, setExtension] = React.useState("");
  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/plot/image/" + props.image, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Service Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.datatype + "base64," + response.data);
        setExtension(props.image.split(".")[1]);
      });
  }, []);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let phoneNumber = props.callnow;

  if (phoneNumber.charAt(0) === "0") {
    phoneNumber = "+92" + phoneNumber.slice(1);
  }

  return (
    <div key={props.id} style={{ margin: "8px" }}>
      <Fade bottom duration={1500}>
        <Card
          style={{
            marginBottom: "2.2em",
            // marginLeft: "2em",
            // marginRight: "2em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Link key={props.id} to={`/plot-details-residential/${props.id}`}>
              <Card.Img className="" src={file} />
            </Link>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Card.Title style={{ marginLeft: "1em" }}>
                <h3
                  className="ltn__blog-title"
                  style={{
                    width: "100%",
                    // textAlign: "center",
                    marginTop: "0.5em",
                    fontSize: "1.3em",
                  }}
                >
                  <Link
                    key={props._id}
                    to={`/plot-details-residential/${props.id}`}
                  >
                    Price : {props.price ? props.price : props.title}
                  </Link>
                </h3>
                {/* <div
                  className="ltn__blog-meta-btn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "-0.6em",
                    marginLeft: "0.5em",
                  }}
                >
                  <div
                    className="ltn__blog-meta"
                    style={{ marginBottom: "0.3em" }}
                  >
                    <ul>
                      <li className="ltn__blog-date">
                        <i className="far flaticon-pin" />
                        {props.city}
                        {props.phase}
                      </li>
                    </ul>
                  </div>
                </div> */}

                <div>
                  <h6
                    style={{
                      // marginTop: "0.1em",
                      marginTop: "-0.9em",
                      fontSize: "1em",
                      fontWeight: "600",
                    }}
                  >
                    DHA {props.phase}
                  </h6>
                </div>
                <div>
                  <p
                    style={{
                      // marginTop: "0.1em",
                      marginTop: "-0.6em",
                      fontSize: "1em",
                      fontWeight: "500",
                    }}
                  >
                    {props.size} | {props.sector} | {props.Plotcategory}
                  </p>
                </div>
              </Card.Title>
            </div>

            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                flex: "1",
                marginTop: "-1rem",
                marginBottom: "1rem",
                marginLeft: "1em",
                //  marginTop: "-2rem", width: "100%" , height: "5%"
              }}
            >
              <a
                style={{
                  borderRadius: "5px",
                  // width: "4.5em",
                  // width: "40%",
                  width: "29%",
                  height: "2em",
                  letterSpacing: "0.1em",
                  fontSize: "0.9em",
                  padding: "0.2em 0.5em",
                  marginTop: "auto",
                }}
                href={"tel:" + ((props.callnow) || "")}
                className="theme-btn-call btn"
              >
                <strong>
                  Call
                  {/* {((props.details && props.details.callnow) || "").replace(
                    /(\d{4})(\d+)/,
                    "$1-$2"
                  )} */}
                </strong>
              </a>
              <a
                style={{
                  borderRadius: "5px",
                  width: "2em",
                  height: "1.8em",
                  backgroundColor: "#25D366", // WhatsApp green color
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href={`https://wa.me/${phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp style={{ color: "white" }} />
              </a>
            </div>
          </div>
        </Card>
      </Fade>
    </div>
  );
}

export default RecentSliderV1;
