import React from "react";
import Navbar from "./global-components/navbar-v3";
import PlotsFlats from "./shop-components/plots-flats-grid-rent";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
// import Thumbnail from "./section-components/thumbnail-flats";
import Thumbnail from "./section-components/thumbnail-houses";
import { Helmet } from "react-helmet";

const ShopGrid_V1 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div>
      <Helmet>
        <title>Propex | Flats For Rent</title>
      </Helmet>
      <Navbar />
      {/* <div
        className=" navbar-image ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image "
        data-bs-bg={publicUrl + "assets/img/houses.png"}
      /> */}
      <PlotsFlats />
      <Thumbnail />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default ShopGrid_V1;
